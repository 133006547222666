import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AddReasonForChange,
  AnnotationClassType,
  Indication,
  Modality,
  NestValues,
  onlyUpdates,
  StudyAdminView,
  StudyView,
  UpdateValueWithReasonForChange,
  User,
  RoleName,
  Users,
  UsersInStudy,
  UUID,
  UserInStudy
} from "../models";
import {
  addStudyAccess,
  createStudy,
  fetchStudy,
  fetchUsers,
  fetchUsersInStudy,
  removeStudyAccess,
  updateStudy
} from "../api";
import { Resource, WriteResource } from "../types";
import { equals, union, without } from "ramda";
import { areDifferent, idsOnly } from "../utils";
import { convAnno, convAnnoOld } from "../pages/StudyConfiguration";
import { RootState } from "../store";
import { castDraft } from "immer";
import { setEmbeddedMode } from "./config";

export interface StudyFormFields {
  readonly name: string;
  readonly sponsor: string | null;
  readonly protocolIds: ReadonlyArray<string>;
  readonly visitIds: ReadonlyArray<string>;
  readonly labVisitIds: ReadonlyArray<string>;
  readonly indications: ReadonlyArray<Indication>;
  readonly segments: number | null;
  readonly anatomicalSegments: ReadonlyArray<string>;
  readonly modality: ReadonlyArray<Modality>;
  readonly uploaders: Users;
  readonly readers: Users;
  readonly onHold: Boolean;
  readonly onHoldReason: string | null;
  readonly hpfAnnotationClasses: ReadonlyArray<AnnotationClassForm<"HPF">>;
  readonly pointAnnotationClasses: ReadonlyArray<AnnotationClassForm<"POINT">>;
  readonly freehandAnnotationClasses: ReadonlyArray<AnnotationClassForm<"FREEHAND">>;
  readonly multilineAnnotationClasses: ReadonlyArray<AnnotationClassForm<"MULTILINE">>;
}

type Form = NestValues<StudyFormFields>;

export type UpdateStudyForm = AddReasonForChange<Form>;

export type CreateStudyForm = Form;

export type StudyForm = UpdateStudyForm | CreateStudyForm;

export type UsersResource = Resource<Users>;

// eslint-disable-next-line
export interface AnnotationClassForm<T extends AnnotationClassType> {
  readonly id?: string;
  readonly name: string;
  readonly color: string;
  readonly sortOrder: number | null;
  readonly type: string; // T;
  readonly enabled: boolean;
  readonly count?: number;
  readonly deleted: boolean;
}

export type EditableAnnotationClassFields = Extract<
  keyof AnnotationClassForm<AnnotationClassType>,
  "name" | "color" | "sortOrder"
>;

function roleToProperty(
  role: AssignableUserRoles
): Extract<keyof StudyForm, "readers" | "uploaders"> {
  switch (role) {
    case RoleName.CR:
      return "readers";
    case RoleName.LabTech:
      return "uploaders";
    case RoleName.Uploader:
      return "uploaders";
    default: {
      const impossible: never = role;
      return impossible;
    }
  }
}

function validateAddUserButton(form: NewUserSearchForm): Boolean {
  if (form.selectedRoleId && form.selectedUsers.length > 0) {
    return false;
  } else {
    return true;
  }
}

export type AssignableUserRoles = RoleName.CR | RoleName.LabTech | RoleName.Uploader;

function convertUser(user: UserInStudy): User {
  const r = {
    ...user,
    actions: null,
    workAsRole: null,
    isSystemAdmin: null,
    roleName: "",
    organizationName: null
  };
  return r as User;
}

function adminStudyViewToForm(studyView: StudyAdminView): UpdateStudyForm {
  return {
    name: {
      value: studyView.study.name
    },
    sponsor: {
      value: studyView.study.sponsor
    },
    protocolIds: {
      value: studyView.study.protocolIds
    },
    visitIds: {
      value: studyView.study.visitIds
    },
    labVisitIds: {
      value: studyView.study.labVisitIds
    },
    indications: {
      value: studyView.study.indications
    },
    segments: {
      value: studyView.study.segments
    },
    anatomicalSegments: {
      value: studyView.study.anatomicalSegments
    },
    modality: {
      value: studyView.study.modality
    },
    uploaders: {
      value: studyView.uploaders.map(uis => {
        return convertUser(uis);
      })
    },
    readers: {
      value: studyView.readers.map(uis => {
        return convertUser(uis);
      })
    },
    onHold: {
      value: studyView.study.onHold
    },
    onHoldReason: {
      value: studyView.study.onHoldReason
    },
    hpfAnnotationClasses: {
      value: studyView.hpfAnnotationClasses.map(ac => {
        return { ...ac.annotationClass, count: ac.count, deleted: false };
      })
    },
    pointAnnotationClasses: {
      value: studyView.pointAnnotationClasses.map(ac => {
        return { ...ac.annotationClass, count: ac.count, deleted: false };
      })
    },
    freehandAnnotationClasses: {
      value: studyView.freehandAnnotationClasses.map(ac => {
        return { ...ac.annotationClass, count: ac.count, deleted: false };
      })
    },
    multilineAnnotationClasses: {
      value: studyView.multilineAnnotationClasses.map(ac => {
        return { ...ac.annotationClass, count: ac.count, deleted: false };
      })
    }
  };
}

export interface NewUserSearchForm {
  readonly usersSearchText: string;
  readonly usersSearchResults: Resource<Users>;
  readonly selectedUsers: Array<User>;
  readonly selectedRoleId: UUID | null;
  readonly disableAddUserButton: Boolean;
}

export interface StudyConfigurationState {
  readonly savedStudy: StudyAdminView | null;
  readonly existingStudy: StudyView | null;
  readonly study: WriteResource<StudyForm, StudyAdminView>;
  readonly uploadersSearchText: string;
  readonly readersSearchText: string;
  readonly uploaderSearchResults: UsersResource;
  readonly readerSearchResults: UsersResource;
  readonly usersInStudy: Resource<UsersInStudy>;
  readonly newUserSearchForm: NewUserSearchForm;
}

export const initialAnnotationClassState: AnnotationClassForm<AnnotationClassType> = {
  name: "",
  color: "",
  sortOrder: null,
  type: "FREEFORM",
  enabled: true,
  deleted: false
};

function isAnnotationClassFormBlank(form: AnnotationClassForm<AnnotationClassType>): boolean {
  return (
    (form.name === "" && form.color === "" && form.sortOrder === null) || form.deleted === true
  );
}

export const initialState: StudyConfigurationState = {
  savedStudy: null,
  existingStudy: null,
  study: {
    data: {
      name: { value: "" },
      sponsor: { value: null },
      protocolIds: { value: [] },
      visitIds: { value: [] },
      labVisitIds: { value: [] },
      indications: { value: [] },
      segments: { value: null },
      anatomicalSegments: { value: [] },
      modality: { value: [] },
      uploaders: { value: [] },
      readers: { value: [] },
      onHold: { value: false },
      onHoldReason: { value: null },
      hpfAnnotationClasses: { value: [] },
      pointAnnotationClasses: { value: [] },
      freehandAnnotationClasses: { value: [] },
      multilineAnnotationClasses: { value: [] }
    }
  },
  uploadersSearchText: "",
  readersSearchText: "",
  uploaderSearchResults: {
    isPending: false
  },
  readerSearchResults: {
    isPending: false
  },
  usersInStudy: {
    isPending: false
  },
  newUserSearchForm: {
    usersSearchText: "",
    usersSearchResults: {
      isPending: false
    },
    selectedUsers: [],
    selectedRoleId: null,
    disableAddUserButton: true
  }
};

export interface AssignUserToStudyParams {
  readonly role: AssignableUserRoles;
  readonly user: User;
}

// thunks
export const studyFetchRequest = createAsyncThunk(
  "studyConfiguration/studyFetchRequest",
  async (studyId: string, thunkApi) => {
    const { dispatch } = thunkApi;
    const response = await fetchStudy(studyId);
    dispatch(setEmbeddedMode(response.isNoto || false));
    return response;
  }
);

export interface UsersInStudyFetchParams {
  readonly studyId: UUID;
  readonly roleIdFilter: UUID | null;
}

export const usersInStudyFetch = createAsyncThunk(
  "studyConfiguration/usersInStudyFetch",
  async (params: UsersInStudyFetchParams) => {
    const response = await fetchUsersInStudy(params.studyId, params.roleIdFilter);
    return response;
  }
);

export const searchUploadersRequest = createAsyncThunk(
  "studyConfiguration/searchUploadersRequest",
  async (name: string, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState() as RootState;
    const response = await fetchUsers(
      name,
      RoleName.LabTech,
      true,
      state.studyConfiguration.study.data.uploaders.value.map(r => r.id)
    );
    return response;
  }
);

export const searchReadersRequest = createAsyncThunk(
  "studyConfiguration/searchReadersRequest",
  async (name: string, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState() as RootState;
    const response = await fetchUsers(
      name,
      RoleName.CR,
      true,
      state.studyConfiguration.study.data.readers.value.map(r => r.id)
    );
    return response;
  }
);

export interface SearchUsersRequestParams {
  name: string;
  excludedUserIds: ReadonlyArray<UUID>;
}

export const searchUsersRequest = createAsyncThunk(
  "studyConfiguration/searchUsersRequest",
  async (params: SearchUsersRequestParams) => {
    const response = await fetchUsers(params.name, undefined, true, params.excludedUserIds);
    return response;
  }
);

interface UpdatedChange<T> {
  readonly value: T;
  readonly reasonForChange?: string | undefined;
}

export function convertValueWithRFC<T>(
  uc: UpdatedChange<T> | undefined
): UpdateValueWithReasonForChange<T> | undefined {
  if (uc === undefined) {
    return undefined;
  } else {
    return {
      value: uc.value,
      reasonForChange: uc.reasonForChange || ""
    };
  }
}

export const createOrUpdateStudyRequest = createAsyncThunk(
  "studyConfiguration/createOrUpdateStudyRequest",
  async (_: void, thunkApi) => {
    const { getState } = thunkApi;
    const reduxState = getState() as RootState;
    const state = reduxState.studyConfiguration;

    // Filter out completely blank point annotation class values since the form adds those by
    // default
    const newStudy = {
      ...state.study.data,
      hpfAnnotationClasses: {
        ...state.study.data.hpfAnnotationClasses,
        value: state.study.data.hpfAnnotationClasses.value.filter(
          hpfAnnotationClass => !isAnnotationClassFormBlank(hpfAnnotationClass)
        )
      },
      pointAnnotationClasses: {
        ...state.study.data.pointAnnotationClasses,
        value: state.study.data.pointAnnotationClasses.value.filter(
          pointAnnotationClass => !isAnnotationClassFormBlank(pointAnnotationClass)
        )
      },
      freehandAnnotationClasses: {
        ...state.study.data.freehandAnnotationClasses,
        value: state.study.data.freehandAnnotationClasses.value.filter(
          freehandAnnotationClass => !isAnnotationClassFormBlank(freehandAnnotationClass)
        )
      },
      multilineAnnotationClasses: {
        ...state.study.data.multilineAnnotationClasses,
        value: state.study.data.multilineAnnotationClasses.value.filter(
          multilineAnnotationClass => !isAnnotationClassFormBlank(multilineAnnotationClass)
        )
      }
    };

    const cleanedStudyData: StudyForm = {
      ...state.study.data,
      hpfAnnotationClasses: {
        value: state.study.data.hpfAnnotationClasses.value.filter(
          hpfAnnotationClass => !isAnnotationClassFormBlank(hpfAnnotationClass)
        )
      },
      pointAnnotationClasses: {
        value: state.study.data.pointAnnotationClasses.value.filter(
          pointAnnotationClass => !isAnnotationClassFormBlank(pointAnnotationClass)
        )
      },
      freehandAnnotationClasses: {
        value: state.study.data.freehandAnnotationClasses.value.filter(
          freehandAnnotationClass => !isAnnotationClassFormBlank(freehandAnnotationClass)
        )
      },
      multilineAnnotationClasses: {
        value: state.study.data.multilineAnnotationClasses.value.filter(
          multilineAnnotationClass => !isAnnotationClassFormBlank(multilineAnnotationClass)
        )
      }
    };

    type UserFields = keyof Pick<StudyFormFields, "uploaders" | "readers">;

    const noChangeStudyUsersField = (field: UserFields, update: any) => {
      const diff =
        state?.savedStudy &&
        update?.value &&
        areDifferent(idsOnly(state?.savedStudy[field]), idsOnly(update.value));
      const rfc: string = update?.reasonForChange || "";
      return !(diff && rfc.length > 0);
    };

    type AnnotationClassFields = keyof Pick<
      StudyFormFields,
      | "hpfAnnotationClasses"
      | "pointAnnotationClasses"
      | "freehandAnnotationClasses"
      | "multilineAnnotationClasses"
    >;

    const noChangeStudyAnnotationField = (field: AnnotationClassFields, update: any) => {
      const newVal = update?.value.map(convAnno);
      const oldVal = state?.savedStudy && state?.savedStudy[field].map(convAnnoOld);
      const diff = oldVal && !equals(oldVal, newVal);
      const rfc: string = update?.reasonForChange || "";
      return !(diff && rfc.length > 0);
    };

    const updates =
      state.savedStudy && onlyUpdates(adminStudyViewToForm(state.savedStudy), newStudy);

    if (updates && state.savedStudy) {
      const response = await updateStudy(
        state.savedStudy.study.id,
        state.savedStudy.study.name !== updates.name?.value
          ? convertValueWithRFC(updates.name)
          : undefined,
        state.savedStudy.study.sponsor !== updates.sponsor?.value
          ? convertValueWithRFC(updates.sponsor)
          : undefined,
        state.savedStudy.study.protocolIds !== updates.protocolIds?.value
          ? convertValueWithRFC(updates.protocolIds)
          : undefined,
        state.savedStudy.study.visitIds !== updates.visitIds?.value
          ? convertValueWithRFC(updates.visitIds)
          : undefined,
        state.savedStudy.study.labVisitIds !== updates.labVisitIds?.value &&
          updates?.visitIds?.reasonForChange
          ? convertValueWithRFC({
              value: updates.labVisitIds?.value as string[],
              reasonForChange: updates?.visitIds?.reasonForChange
            })
          : undefined,
        state.savedStudy.study.indications !== updates.indications?.value
          ? convertValueWithRFC(updates.indications)
          : undefined,
        state.savedStudy.study.segments !== updates.segments?.value
          ? convertValueWithRFC(updates.segments)
          : undefined,
        state.savedStudy.study.anatomicalSegments !== updates.anatomicalSegments?.value
          ? convertValueWithRFC(updates.anatomicalSegments)
          : undefined,
        state.savedStudy.study.modality !== updates.modality?.value
          ? convertValueWithRFC(updates.modality)
          : undefined,
        noChangeStudyUsersField("uploaders", updates.uploaders)
          ? undefined
          : convertValueWithRFC(updates.uploaders),
        noChangeStudyUsersField("readers", updates.readers)
          ? undefined
          : convertValueWithRFC(updates.readers),
        state.savedStudy.study.onHold !== updates.onHold?.value
          ? convertValueWithRFC(updates.onHold)
          : undefined,
        state.savedStudy.study.onHoldReason !== updates.onHoldReason?.value
          ? convertValueWithRFC(updates.onHoldReason)
          : undefined,
        noChangeStudyAnnotationField("hpfAnnotationClasses", updates.hpfAnnotationClasses)
          ? undefined
          : convertValueWithRFC(updates.hpfAnnotationClasses),
        noChangeStudyAnnotationField("pointAnnotationClasses", updates.pointAnnotationClasses)
          ? undefined
          : convertValueWithRFC(updates.pointAnnotationClasses),
        noChangeStudyAnnotationField("freehandAnnotationClasses", updates.freehandAnnotationClasses)
          ? undefined
          : convertValueWithRFC(updates.freehandAnnotationClasses),
        noChangeStudyAnnotationField(
          "multilineAnnotationClasses",
          updates.multilineAnnotationClasses
        )
          ? undefined
          : convertValueWithRFC(updates.multilineAnnotationClasses)
      );
      return response;
    } else {
      const response = await createStudy(
        cleanedStudyData.name.value,
        cleanedStudyData.sponsor.value,
        cleanedStudyData.protocolIds.value,
        cleanedStudyData.visitIds.value,
        cleanedStudyData.labVisitIds.value,
        cleanedStudyData.indications.value,
        cleanedStudyData.segments.value,
        cleanedStudyData.anatomicalSegments.value,
        cleanedStudyData.modality.value,
        cleanedStudyData.uploaders.value,
        cleanedStudyData.readers.value,
        cleanedStudyData.onHold.value,
        cleanedStudyData.onHoldReason.value,
        cleanedStudyData.hpfAnnotationClasses.value,
        cleanedStudyData.pointAnnotationClasses.value,
        cleanedStudyData.freehandAnnotationClasses.value,
        cleanedStudyData.multilineAnnotationClasses.value
      );

      return response;
    }
  }
);

export const addStudyAccessRequest = createAsyncThunk(
  "studyConfiguration/addStudyAccessRequest",
  async (studyId: UUID, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const state = (getState() as RootState).studyConfiguration;
    if (state.newUserSearchForm.selectedUsers[0] && state.newUserSearchForm.selectedRoleId) {
      const response = await addStudyAccess(
        studyId,
        state.newUserSearchForm.selectedUsers[0].id,
        state.newUserSearchForm.selectedRoleId
      );
      dispatch(clearSelectedUser());
      dispatch(resetNewUserSearchForm());
      return response;
    }
  }
);

export interface RemoveStudyAccessRequestParams {
  readonly userId: UUID;
  readonly studyId: UUID;
  readonly roleId: UUID | null;
}

export const removeStudyAccessRequest = createAsyncThunk(
  "studyConfiguration/removeStudyAccessRequest",
  async (params: RemoveStudyAccessRequestParams, thunkApi) => {
    const { dispatch } = thunkApi;
    const response = await removeStudyAccess(params.userId, params.studyId, params.roleId);
    dispatch(resetNewUserSearchForm());
    return response;
  }
);

export const studyConfigurationSlice = createSlice({
  name: "studyConfiguration",
  initialState: initialState,
  reducers: {
    changeStudy: (state, action: PayloadAction<UpdateStudyForm>) => {
      state.study.data = castDraft(action.payload);
    },
    studyReset: () => {
      return initialState;
    },
    assignUserToStudy: (state, action: PayloadAction<AssignUserToStudyParams>) => {
      const roleProperty = roleToProperty(action.payload.role);
      if ("data" in state.study) {
        state.study.data = {
          ...state.study.data,
          [roleProperty]: {
            ...state.study.data[roleProperty],
            value: union(state.study.data[roleProperty].value, [action.payload.user])
          }
        };
      }
    },
    unassignUserFromStudy: (state, action: PayloadAction<AssignUserToStudyParams>) => {
      const roleProperty = roleToProperty(action.payload.role);
      if ("data" in state.study) {
        state.study.data = {
          ...state.study.data,
          [roleProperty]: {
            ...state.study.data[roleProperty],
            value: without([action.payload.user], state.study.data[roleProperty].value)
          }
        };
      }
    },
    addSelectedUser: (state, action: PayloadAction<User>) => {
      state.newUserSearchForm.selectedUsers = castDraft([action.payload]);
      state.newUserSearchForm.disableAddUserButton = validateAddUserButton(state.newUserSearchForm);
    },
    clearSelectedUser: state => {
      state.newUserSearchForm.selectedUsers = [];
      state.newUserSearchForm.disableAddUserButton = validateAddUserButton(state.newUserSearchForm);
    },
    setNewStudyAccessFormRoleId: (state, action: PayloadAction<UUID>) => {
      state.newUserSearchForm = {
        ...state.newUserSearchForm,
        selectedRoleId: action.payload
      };
      state.newUserSearchForm.disableAddUserButton = validateAddUserButton(state.newUserSearchForm);
    },
    resetNewUserSearchForm: state => {
      state.newUserSearchForm = {
        usersSearchText: "",
        usersSearchResults: {
          isPending: false
        },
        selectedUsers: [],
        selectedRoleId: null,
        disableAddUserButton: true
      };
    }
  },
  extraReducers: builder => {
    builder.addCase(studyFetchRequest.fulfilled, (state, action) => {
      state.existingStudy = castDraft(action.payload);
      if ("readers" in action.payload) {
        state.savedStudy = castDraft(action.payload);
        state.study.data = castDraft(adminStudyViewToForm(action.payload));
      }
    });
    builder.addCase(studyFetchRequest.rejected, (state, action) => {
      state.study = {
        ...state.study,
        errorMessage: action.error.message || ""
      };
    });

    builder.addCase(usersInStudyFetch.pending, state => {
      state.usersInStudy = { isPending: true };
    });
    builder.addCase(usersInStudyFetch.fulfilled, (state, action) => {
      state.usersInStudy = { resource: castDraft(action.payload) };
    });
    builder.addCase(usersInStudyFetch.rejected, (state, action) => {
      state.usersInStudy = { errorMessage: action.error.message || "" };
    });

    builder.addCase(searchUploadersRequest.pending, (state, action) => {
      state.uploadersSearchText = action.meta.arg;
      state.uploaderSearchResults = { isPending: true };
    });
    builder.addCase(searchUploadersRequest.fulfilled, (state, action) => {
      const uploaders = action.payload.map(user => ({
        ...user,
        role: RoleName.LabTech
      }));
      state.uploaderSearchResults = castDraft({ resource: uploaders });
    });
    builder.addCase(searchUploadersRequest.rejected, (state, action) => {
      state.uploaderSearchResults = { errorMessage: action.error.message || "" };
    });
    builder.addCase(searchReadersRequest.pending, (state, action) => {
      if (action.payload !== undefined) {
        state.readersSearchText = action.payload;
      }
      state.readerSearchResults = { isPending: true };
    });
    builder.addCase(searchReadersRequest.fulfilled, (state, action) => {
      const readers = action.payload.map(user => ({
        ...user,
        role: RoleName.CR
      }));
      state.readerSearchResults = castDraft({ resource: readers });
    });
    builder.addCase(searchReadersRequest.rejected, (state, action) => {
      state.readerSearchResults = { errorMessage: action.error.message || "" };
    });
    builder.addCase(searchUsersRequest.pending, (state, action) => {
      state.newUserSearchForm.usersSearchText = action.meta.arg.name;
      state.newUserSearchForm.usersSearchResults = { isPending: true };
    });
    builder.addCase(searchUsersRequest.fulfilled, (state, action) => {
      state.newUserSearchForm.usersSearchResults = castDraft({ resource: action.payload });
    });
    builder.addCase(searchUsersRequest.rejected, (state, action) => {
      state.newUserSearchForm.usersSearchResults = { errorMessage: action.error.message || "" };
    });

    builder.addCase(createOrUpdateStudyRequest.pending, state => {
      state.study = {
        data: state.study.data,
        isPending: true
      };
    });
    builder.addCase(createOrUpdateStudyRequest.fulfilled, state => {
      state.existingStudy = null;
      state.savedStudy = null;
      if ("data" in state.study) {
        state.study = {
          data: state.study.data
        };
      }
    });
    builder.addCase(createOrUpdateStudyRequest.rejected, (state, action) => {
      if ("data" in state.study && "readers" in state.study.data) {
        state.study = {
          data: state.study.data,
          errorMessage: action.error.message || ""
        };
      }
    });
  }
});

export const {
  changeStudy,
  studyReset,
  assignUserToStudy,
  unassignUserFromStudy,
  addSelectedUser,
  clearSelectedUser,
  setNewStudyAccessFormRoleId,
  resetNewUserSearchForm
} = studyConfigurationSlice.actions;

export default studyConfigurationSlice.reducer;
