import parse from "date-fns/parse";
import React, { useEffect } from "react";

import {
  Appearance,
  Box,
  Button,
  Callout,
  Heading,
  Icon,
  Intent,
  Label,
  Select,
  Text
} from "@blasterjs/core";
import DebouncedTextInput from "../components/DebouncedTextInput";
import Page, { PageBody, PageHeader, PageHeading } from "../components/Page";
import { DateFilter, Indication, StudyListView, StudyListViews, userLabel } from "../models";

import { useAppDispatch, useAppSelector } from "../hooks";
import {
  ReportType,
  requestReportCsv,
  setReportDateFilter,
  setReportStudyData,
  setReportUserData
} from "../slices/reports";
import { studiesFetch } from "../slices/studies";
import { usersFetch } from "../slices/users";
import {
  requestAuditTrailCsv,
  requestAuditTrailPdf,
  setAuditTrailStudyData,
  setAuditTrailUserData
} from "../slices/auditTrail";
import TooltipFlyout, { TooltipLink } from "../components/Tooltip";
import { RolePermissions } from "../permissions";

interface ReportConfig {
  readonly key: ReportType;
  readonly label: string;
  readonly h2Label: string;
  readonly userPick: boolean;
  readonly global: boolean;
}

const reportKeysLabels: ReadonlyArray<ReportConfig> = [
  {
    key: "image-details",
    label: "Image Details Report",
    h2Label: "A report of all image metadata on a study level.",
    userPick: false,
    global: true
  },
  {
    key: "image-details-sponsor",
    label: "Sponsor Image Details Report",
    h2Label: "A report of all image metadata at study level, designed for Sponsor use",
    userPick: false,
    global: false
  },
  {
    key: "hpf-annotations",
    label: "HPF Annotations Report",
    h2Label: "A report of all point annotations within high power field data.",
    userPick: false,
    global: false
  },
  {
    key: "celiac-annotations",
    label: "Celiac Annotations Report",
    h2Label: "A report of all celiac annotations for images.",
    userPick: false,
    global: false
  },
  {
    key: "query",
    label: "Query Report",
    h2Label: "A report of query history on a study level.",
    userPick: false,
    global: false
  },
  {
    key: "access",
    label: "Access Report",
    h2Label: "A report of access history on a study level.",
    userPick: false,
    global: false
  }
];

const dateFilterLabels: ReadonlyArray<{
  readonly key: keyof DateFilter;
  readonly label: string;
}> = [
  {
    key: "start",
    label: "Start Date"
  },
  {
    key: "end",
    label: "End Date"
  }
];

const DAY_FORMAT = "dd-MMM-yyyy";

function parseDateString(dateString: string): Date | "invalid" {
  const parsedDate = new Date(parse(dateString, DAY_FORMAT, new Date()));
  // See also: https://stackoverflow.com/a/1353711/1464098
  const isValidDate = parsedDate instanceof Date && !isNaN(parsedDate.getTime());
  return isValidDate ? parsedDate : "invalid";
}

function parseDateStringAddOne(dateString: string): Date | "invalid" {
  const parsedDate = new Date(parse(dateString, DAY_FORMAT, new Date()));
  // See also: https://stackoverflow.com/a/1353711/1464098
  if (parsedDate instanceof Date && !isNaN(parsedDate.getTime())) {
    parsedDate.setDate(parsedDate.getDate() + 1);
    return parsedDate;
  } else {
    return "invalid";
  }
}

const Reports = () => {
  const dispatch = useAppDispatch();

  const studies = useAppSelector(state => state.studies.studies);
  const users = useAppSelector(state => state.users.users);
  const loggedInUser = useAppSelector(state => state.auth.loggedInUser);
  const auditTrailState = useAppSelector(state => state.auditTrail);
  const reportState = useAppSelector(state => state.reports);

  const studiesFetched = "resource" in studies;
  const usersFetched = "resource" in users;
  const userIsAdmin = "resource" in loggedInUser && loggedInUser.resource.isSystemAdmin();
  const userIsEitherAdmins =
    "resource" in loggedInUser &&
    (loggedInUser.resource.isSystemAdmin() || loggedInUser.resource.isStudyAdmin());

  useEffect(() => {
    !studiesFetched && dispatch(studiesFetch());
    // NOTE: Only admins can filter audit trail by user
    !usersFetched && userIsEitherAdmins && dispatch(usersFetch());
  }, [studiesFetched, usersFetched, userIsAdmin]);

  const displayErrorMessage = (errorMessage: string | undefined) =>
    errorMessage !== undefined ? (
      <Box width="58em" m={2}>
        <Callout intent={Intent.DANGER}>
          <Text>{errorMessage}</Text>
        </Callout>
      </Box>
    ) : null;

  const emptyOption = <option key="" value="" />;
  const requestingCsv = auditTrailState.requestingCsv || "isPending" in Object.values(reportState);

  const isCeliacStudy = (study: StudyListView) =>
    study.studyView.study.indications.includes(Indication.CeliacDisease);

  const celiacStudies: StudyListViews =
    ("resource" in studies && studies.resource.filter(s => isCeliacStudy(s))) || [];

  const studySelect = (
    reportType: ReportType | null,
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  ) =>
    "resource" in studies ? (
      <Label>
        Study
        <Select onChange={onChange} disabled={requestingCsv}>
          {emptyOption}
          {(reportType == "celiac-annotations" ? celiacStudies : studies.resource).map(
            selectedStudy => {
              return (
                <option
                  key={selectedStudy.studyView.study.id}
                  value={selectedStudy.studyView.study.id}
                >
                  {selectedStudy.studyView.study.name}
                </option>
              );
            }
          )}
        </Select>
      </Label>
    ) : null;

  const selectStudyForReport = (reportType: ReportType) => (
    <Box display="flex" key={reportType}>
      {studySelect(reportType, (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = e.target.selectedOptions[0];
        selectedOption &&
          dispatch(
            setReportStudyData({
              reportType: reportType,
              studyData: {
                id: selectedOption.value,
                name: selectedOption.text
              }
            })
          );
      })}
      {(reportType === "celiac-annotations" || reportType === "hpf-annotations") &&
        dateFilterLabels.map(({ key, label }) => (
          <Label key={key} ml={2}>
            {label}
            <DebouncedTextInput
              placeholder="DD-MMM-YYYY"
              defaultValue=""
              onValueChange={(dateString: string) => {
                dispatch(
                  setReportDateFilter({
                    reportType: reportType,
                    dateFilter: {
                      ...reportState[reportType].data?.dateFilter,
                      [key]:
                        dateString.trim() === ""
                          ? undefined
                          : key == "end"
                          ? parseDateStringAddOne(dateString)
                          : parseDateString(dateString)
                    }
                  })
                );
              }}
            />
          </Label>
        ))}
    </Box>
  );

  const userSelect = (onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void) =>
    "resource" in users ? (
      <Label>
        User
        <Select onChange={onChange} disabled={requestingCsv}>
          {emptyOption}
          {users.resource.map(user => {
            return (
              <option key={user.id} value={user.id}>
                {userLabel(user)}
              </option>
            );
          })}
        </Select>
      </Label>
    ) : null;

  const downloadAuditTrailButton =
    "resource" in studies && "resource" in loggedInUser ? (
      <div className={"menutool"}>
        <Button
          id="pdf_b_061024"
          iconBefore="document"
          className="buttontool"
          data-tooltip={true}
          appearance={Appearance.MINIMAL}
        >
          Download Report
          <Icon name="caretDown" />
          <TooltipFlyout placement={"bottom-start"}>
            {loggedInUser.resource.can([RolePermissions.R_GenerateAuditTrailPDFonly]) ? (
              <TooltipLink
                className="ttmenu"
                onClick={() => {
                  const d = document.getElementById("pdf_b_061024");
                  d && d.click();
                  dispatch(requestAuditTrailPdf());
                }}
              >
                Download PDF
              </TooltipLink>
            ) : null}
            {loggedInUser.resource.can([RolePermissions.R_GenerateAuditTrailPDForCSV]) ? (
              <TooltipLink
                className="ttmenu"
                onClick={() => {
                  const d = document.getElementById("pdf_b_061024");
                  d && d.click();
                  dispatch(requestAuditTrailCsv());
                }}
              >
                Download CSV
              </TooltipLink>
            ) : null}
          </TooltipFlyout>
        </Button>
      </div>
    ) : null;

  const downloadReportButton = (reportType: ReportType, global: boolean) =>
    "resource" in studies ? (
      <Button
        key={reportType}
        iconBefore="document"
        onClick={() => dispatch(requestReportCsv(reportType))}
        disabled={
          !(global && userIsAdmin) &&
          (requestingCsv ||
            !reportState[reportType]?.data?.studyData?.id ||
            reportState[reportType]?.data?.dateFilter?.start === "invalid" ||
            reportState[reportType]?.data?.dateFilter?.end === "invalid")
        }
        isLoading={requestingCsv}
        appearance="prominent"
        intent="primary"
      >
        Download Report
      </Button>
    ) : null;

  const userBox = (reportType: ReportType) => (
    <Box
      width="auto"
      mr={"resource" in loggedInUser && loggedInUser.resource.isSystemAdmin() ? 2 : 0}
    >
      {userSelect((e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = e.target.selectedOptions[0];
        selectedOption &&
          dispatch(
            setReportUserData({
              reportType: reportType,
              userData: {
                id: selectedOption.value,
                name: selectedOption.text
              }
            })
          );
      })}
    </Box>
  );

  return (
    <Page>
      <Box style={{ padding: "0 2rem 4rem" }}>
        <PageHeader>
          <PageHeading>Reports</PageHeading>
        </PageHeader>
        <PageBody>
          <Box>
            <Heading as="h4">Audit Trail</Heading>
            <h2>
              PDF report of the Audit Trail on a Study and User basis. Only one selection required
              to pull report.
            </h2>
            <Box display="flex">{displayErrorMessage(auditTrailState.errorMessage)}</Box>
            <Box display="flex">
              <Box width="auto" mr={2}>
                {studySelect(null, (e: React.ChangeEvent<HTMLSelectElement>) => {
                  const selectedOption = e.target.selectedOptions[0];
                  dispatch(
                    setAuditTrailStudyData(
                      selectedOption
                        ? {
                            id: selectedOption.value,
                            name: selectedOption.text
                          }
                        : undefined
                    )
                  );
                })}
              </Box>
              {/* Only admin users need extra margin to space out the dropdowns; readonly users don't see the user select dropdown */}
              <Box
                width="auto"
                mr={"resource" in loggedInUser && loggedInUser.resource.isSystemAdmin() ? 2 : 0}
              >
                {userSelect((e: React.ChangeEvent<HTMLSelectElement>) => {
                  const selectedOption = e.target.selectedOptions[0];

                  dispatch(
                    setAuditTrailUserData(
                      selectedOption
                        ? {
                            id: selectedOption.value,
                            name: selectedOption.text
                          }
                        : undefined
                    )
                  );
                })}
              </Box>
              <Box width="5rem" style={{ alignSelf: "flex-end" }}>
                {downloadAuditTrailButton}
              </Box>
            </Box>
          </Box>
          {reportKeysLabels.map(({ key, label, h2Label, userPick, global }) => {
            const report = reportState[key];
            return (
              <Box mt={5} key={key}>
                <Heading as="h4">{label}</Heading>
                <h2>{h2Label}</h2>
                <Box display="flex">
                  {displayErrorMessage("errorMessage" in report ? report.errorMessage : undefined)}
                </Box>
                <Box display="flex">
                  <Box width="auto" mr={2}>
                    {selectStudyForReport(key)}
                  </Box>
                  {userPick ? userBox(key) : null}
                  <Box width="5rem" style={{ alignSelf: "flex-end" }}>
                    {downloadReportButton(key, global)}
                  </Box>
                </Box>
              </Box>
            );
          })}
        </PageBody>
      </Box>
    </Page>
  );
};

export default Reports;
