import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ImageManagementCaseRecord, ImageManagementRecord, UUID } from "../models";
import {
  fetchImageManagementCaseRecordByCaseId,
  fetchImageManagementRecordByImageId,
  fetchStudy,
  saveEditColumnValueApi
} from "../api";
import { updateCaseRecordRow, updateImageRecordRow } from "./imageManagement";
import { zip } from "ramda";

export enum DialogMode {
  OpenForEdit = "OPEN_FOR_EDIT",
  Closed = "CLOSED"
}

export enum ImageTableColumns {
  AccessionNumber = "AccessionNumber",
  StudyName = "StudyName",
  ProtocolId = "ProtocolID",
  SiteID = "SiteID",
  SubjectID = "SubjectID",
  VisitID = "VisitID",
  LabVisitID = "LabVisitID",
  HistoProcedureID = "HistoProcedureID",
  EndoProcedureID = "EndoProcedureID",
  Indication = "Indication",
  AnatomicalSegment = "AnatomicalSegment", // biopsy_location
  CollectionDate = "CollectionDate",
  ImageUploadDate = "ImageUploadDate",
  Status = "Status",
  InQuery = "InQuery",
  //Filename = "Filename",
  QaComments = "QaComments",
  Organization = "Organization"
}

export enum LabImageTableColumns {
  Sponsor = "Sponsor",
  AnatomicalSegment = "AnatomicalSegment",
  Filename = "Filename",
  QaComments = "QaComments"
}

export function formatImageTableColumnName(tableColumn: ImageTableColumns): string {
  switch (tableColumn.toString()) {
    case ImageTableColumns.AccessionNumber:
      return "Accession #";
      break;
    default:
      return tableColumn.toString();
  }
}

export enum CaseTableColumns {
  HistoProcedureID = "HistoProcedureID",
  EndoProcedureID = "EndoProcedureID",
  StudyName = "StudyName",
  SiteID = "SiteID",
  SubjectID = "SubjectID",
  VisitID = "VisitID",
  DateCreated = "DateCreated",
  ImagesExpected = "ImagesExpected",
  ImagesReceived = "ImagesReceived",
  Status = "Status",
  CollectionDate = "CollectionDate"
}

export interface EditColumnParams {
  readonly editingColumn: ImageTableColumns | LabImageTableColumns | CaseTableColumns | null;
  readonly row: ImageManagementRecord | ImageManagementCaseRecord | null;
  readonly columnValue: string | null;
  readonly errorMessage: string | null;
}

export interface ChoicePair {
  readonly choiceKey: string;
  readonly value: string;
}

export interface ImageManagementEditTableCellState {
  readonly mode: DialogMode;
  readonly params: EditColumnParams | null;
  readonly valueOptions: ChoicePair[] | null;
}

export const initialState: ImageManagementEditTableCellState = {
  mode: DialogMode.Closed,
  params: null,
  valueOptions: null
};

// thunks
export const openImageManagementEditTableCellDialog = createAsyncThunk(
  "imageManagementEditTableCellSlice/openImageManagementEditTableCellDialog",
  (params: EditColumnParams, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(setOpenImageManagementEditTableCell(params));
  }
);

export interface SaveColumnValueParams {
  readonly editingColumnName: string;
  readonly imageId: UUID;
  readonly newColumnValue: string;
  readonly reasonForChange: string | null;
  readonly tabName: "images" | "cases";
}

export const loadVisitIds = createAsyncThunk(
  "imageManagementEditTableCellSlice/loadVisitIds",
  async (studyId: string) => {
    return await fetchStudy(studyId);
  }
);

export const saveEditColumnValue = createAsyncThunk(
  "imageManagementEditTableCellSlice/saveEditColumnValue",
  async (params: SaveColumnValueParams) => {
    return await saveEditColumnValueApi(
      params.imageId,
      params.tabName,
      params.editingColumnName,
      params.newColumnValue,
      params.reasonForChange
    );
  }
);

export const refreshImageRecordRow = createAsyncThunk(
  "imageManagementEditTableCellSlice/refreshImageRecordRow",
  async (imageId: UUID, thunkApi) => {
    const { dispatch } = thunkApi;

    const imageRecordResponse = await fetchImageManagementRecordByImageId(imageId);

    if (imageRecordResponse && imageRecordResponse.length > 0 && imageRecordResponse[0]) {
      dispatch(updateImageRecordRow(imageRecordResponse[0]));
    }
    return imageRecordResponse;
  }
);

export const saveEditCaseColumnValue = createAsyncThunk(
  "imageManagementEditTableCellSlice/saveEditCaseColumnValue",
  async (params: SaveColumnValueParams) => {
    return await saveEditColumnValueApi(
      params.imageId,
      params.tabName,
      params.editingColumnName,
      params.newColumnValue,
      params.reasonForChange
    );
  }
);

export const refreshCaseRecordRow = createAsyncThunk(
  "imageManagementEditTableCellSlice/refreshCaseRecordRow",
  async (caseId: UUID, thunkApi) => {
    const { dispatch } = thunkApi;

    const caseRecordResponse = await fetchImageManagementCaseRecordByCaseId(caseId);

    if (caseRecordResponse && caseRecordResponse.length > 0 && caseRecordResponse[0]) {
      dispatch(updateCaseRecordRow(caseRecordResponse[0]));
    }
    return caseRecordResponse;
  }
);

function convertToVisitId(vlv: string[]) {
  const newVisitId: string = vlv[0] || "";
  const labVisitId: string = vlv[1] || "";
  return {
    choiceKey: newVisitId,
    value: `${newVisitId} -> ${labVisitId}`
  };
}

export const imageManagementEditTableCellSlice = createSlice({
  name: "imageManagementEditTableCellSlice",
  initialState: initialState,
  reducers: {
    setOpenImageManagementEditTableCell: (state, action) => {
      return {
        ...initialState,
        mode: DialogMode.OpenForEdit,
        params: action.payload
      };
    },
    closeImageManagementEditTableCell: state => {
      state.mode = DialogMode.Closed;
    }
  },
  extraReducers: builder => {
    builder.addCase(saveEditColumnValue.pending, state => {
      if (state.params) {
        state.params.errorMessage = null;
      }
    });
    builder.addCase(saveEditColumnValue.fulfilled, (state, action) => {
      if (!action.payload.success) {
        if (state.params) {
          state.params.errorMessage = action.payload.message && action.payload.message?.toString();
        }
      }
    });
    builder.addCase(saveEditColumnValue.rejected, (state, action) => {
      if (state.params) {
        if ("payload" in action && !(action.payload as any)?.success) {
          const p = action.error as any;
          state.params.errorMessage = p && p["message"];
        } else if (action.error) {
          state.params.errorMessage = action.error.message || null;
        } else {
          state.params.errorMessage = "Unable to edit column successfully";
        }
      }
    });
    builder.addCase(saveEditCaseColumnValue.pending, state => {
      if (state.params) {
        state.params.errorMessage = null;
      }
    });
    builder.addCase(saveEditCaseColumnValue.fulfilled, (state, action) => {
      if (!action.payload.success) {
        if (state.params) {
          state.params.errorMessage = action.payload.message?.toString();
        }
      }
    });
    builder.addCase(saveEditCaseColumnValue.rejected, (state, action) => {
      if (state.params) {
        if ("payload" in action && !(action.payload as any)?.success) {
          const p = action.error as any;
          state.params.errorMessage = p && p["message"];
        } else if (action.error) {
          state.params.errorMessage = action.error.message || null;
        } else {
          state.params.errorMessage = "Unable to edit column successfully";
        }
      }
    });

    builder.addCase(loadVisitIds.pending, state => {
      if (state.params) {
        state.params.errorMessage = null;
      }
    });
    builder.addCase(loadVisitIds.fulfilled, (state, action) => {
      if (!action.payload) {
        if (state.params) {
          state.params.errorMessage = "error loading lab visit ids";
        }
      } else {
        if (state?.params?.editingColumn == ImageTableColumns.VisitID) {
          const zipped: string[][] = zip(
            action.payload.study.visitIds,
            action.payload.study.labVisitIds
          );
          const pairs: ChoicePair[] = zipped.map(convertToVisitId);
          state.valueOptions = [...pairs];
        } else if (state?.params?.editingColumn == ImageTableColumns.LabVisitID) {
          state.valueOptions = action.payload.study.labVisitIds.map((visitId: string) => ({
            choiceKey: visitId,
            value: visitId
          }));
        }
      }
    });
    builder.addCase(loadVisitIds.rejected, (state, action) => {
      if (state.params) {
        if ("payload" in action && !(action.payload as any)?.success) {
          const p = action.error as any;
          state.params.errorMessage = p && p["message"];
        } else if (action.error) {
          state.params.errorMessage = action.error.message || null;
        } else {
          state.params.errorMessage = "Unable to load lab visit ids from study config";
        }
      }
    });
  }
});

export const {
  setOpenImageManagementEditTableCell,
  closeImageManagementEditTableCell
} = imageManagementEditTableCellSlice.actions;
export default imageManagementEditTableCellSlice.reducer;
