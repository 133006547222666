import React, { useState } from "react";

import { Box, Dialog, Label, Icon } from "@blasterjs/core";

import { DialogBody, DialogFooter, DialogHeader } from "./DialogLayout";

import { useAppDispatch, useAppSelector } from "../hooks";
import { DateFilterOptions, formatDateFilterOptions, ImageManagementSearch } from "../api";
import {
  addCaseStudySelection,
  addStudySelection,
  clearSearchFilter,
  closeImageFilterDialog,
  removeCaseStudySelection,
  removeStudySelection,
  setCaseDateFilter,
  setCaseStudyName,
  setDateFilter,
  setStatusFilter,
  setStudyName,
  studiesForUserFetch
} from "../slices/imageManagement";
import { ImageStatusType, Study, formatImageStatusType } from "../models";
import Select, { SelectOfType } from "../components/Select";
import { prop } from "ramda";
import styled from "styled-components";

export const SelectStudies = Select as SelectOfType<Study>;

export interface ImageManagementSearchFiltersProps {
  isOpen: boolean;
  filters: ImageManagementSearch;
}

export type ImageOrCaseControl = "Image" | "Case";

export interface StudyFilterControlProps {
  readonly imageOrCase: ImageOrCaseControl;
}

export const StudyFilterControl = ({ imageOrCase }: StudyFilterControlProps) => {
  const dispatch = useAppDispatch();

  const studies = useAppSelector(state => state.imageManagement.studies);
  const searchText = useAppSelector(state =>
    imageOrCase == "Image"
      ? state.imageManagement.imageResultFilters.filters.studyFilter?.searchText || ""
      : state.imageManagement.caseResultFilters.filters.studyFilter?.searchText || ""
  );

  const selectedStudyFilter = useAppSelector(state =>
    imageOrCase == "Image"
      ? state.imageManagement.imageResultFilters.filters.studyFilter?.selected
      : state.imageManagement.caseResultFilters.filters.studyFilter?.selected
  );

  const onSelectStudy = (study: Study) => {
    if (imageOrCase == "Image") {
      dispatch(addStudySelection(study));
    } else {
      dispatch(addCaseStudySelection(study));
    }
  };

  const onStudyNameChange = (value: string) => {
    //console.log("study text=" + value);
    if (imageOrCase == "Image") {
      dispatch(setStudyName(value));
    } else {
      dispatch(setCaseStudyName(value));
    }
    dispatch(studiesForUserFetch(value));
  };

  const onUnassignStudy = (study: Study) => {
    if (imageOrCase == "Image") {
      dispatch(removeStudySelection(study));
    } else {
      dispatch(removeCaseStudySelection(study));
    }
  };

  return (
    <Box display="block" justifyContent="space-between" mb={2}>
      <Box width="100%">
        <Label>
          Search Studies
          <SelectStudies
            placeholder={"Enter study name"}
            searchText={searchText}
            searchResults={"resource" in studies ? studies.resource : []}
            onSelect={onSelectStudy}
            onChangeSearchText={onStudyNameChange}
            onDeselect={onUnassignStudy}
            selectedItems={selectedStudyFilter ? selectedStudyFilter : []}
            isLoading={"isPending" in studies && studies.isPending}
            format={prop("name")}
          />
        </Label>
      </Box>
    </Box>
  );
};

export interface UploadDateControlProps {
  readonly imageOrCase: ImageOrCaseControl;
}

export const UploadDateControl = ({ imageOrCase }: UploadDateControlProps) => {
  const dispatch = useAppDispatch();

  const dateFilter = useAppSelector(state =>
    imageOrCase == "Image"
      ? state.imageManagement.imageResultFilters.filters.dateFilter
      : state.imageManagement.caseResultFilters.filters.dateFilter
  );

  const filterOnClick = (filter: DateFilterOptions | null) => {
    if (imageOrCase == "Image") dispatch(setDateFilter({ dateRange: filter, customRange: null }));
    else dispatch(setCaseDateFilter({ dateRange: filter, customRange: null }));
  };

  return (
    <div>
      <ol>
        <SearchFilterNavLink
          style={{ cursor: "pointer" }}
          onClick={() => filterOnClick("Yesterday")}
        >
          Yesterday
        </SearchFilterNavLink>
        <SearchFilterNavLink style={{ cursor: "pointer" }} onClick={() => filterOnClick("Last7")}>
          Last 7 Days
        </SearchFilterNavLink>
        <SearchFilterNavLink style={{ cursor: "pointer" }} onClick={() => filterOnClick("Last14")}>
          Last 14 Days
        </SearchFilterNavLink>
        <SearchFilterNavLink style={{ cursor: "pointer" }} onClick={() => filterOnClick("Last60")}>
          Last 60 Days
        </SearchFilterNavLink>
        <SearchFilterNavLink style={{ cursor: "pointer" }} onClick={() => filterOnClick("AllTime")}>
          All time
        </SearchFilterNavLink>
      </ol>
      {dateFilter && dateFilter.dateRange && (
        <ol>
          <hr />
          {formatDateFilterOptions(dateFilter.dateRange)}
          <SearchFilterNavLink onClick={() => filterOnClick(null)}>
            clear <Icon name="cross" />
          </SearchFilterNavLink>
        </ol>
      )}
    </div>
  );
};

const ImageStatusControl = () => {
  const dispatch = useAppDispatch();

  const statusFilter = useAppSelector(
    state => state.imageManagement.imageResultFilters.filters.imageStatusFilter
  );

  const filterOnClick = (filter: ImageStatusType | null) => {
    const statuses = statusFilter ? [...statusFilter] : [];
    if (filter) {
      if (statuses.indexOf(filter) == -1) {
        dispatch(setStatusFilter([...statuses, filter]));
      }
    } else {
      dispatch(setStatusFilter(null));
    }
  };

  return (
    <div>
      <ol>
        <SearchFilterNavLink
          style={{ cursor: "pointer" }}
          key={ImageStatusType.Processing}
          onClick={() => filterOnClick(ImageStatusType.Processing)}
        >
          {formatImageStatusType(ImageStatusType.Processing)}
        </SearchFilterNavLink>
        <SearchFilterNavLink
          style={{ cursor: "pointer" }}
          key={ImageStatusType.FailProcessing}
          onClick={() => filterOnClick(ImageStatusType.FailProcessing)}
        >
          {formatImageStatusType(ImageStatusType.FailProcessing)}
        </SearchFilterNavLink>
        <SearchFilterNavLink
          style={{ cursor: "pointer" }}
          key={ImageStatusType.StudyAssignmentPending}
          onClick={() => filterOnClick(ImageStatusType.StudyAssignmentPending)}
        >
          {formatImageStatusType(ImageStatusType.StudyAssignmentPending)}
        </SearchFilterNavLink>
        <SearchFilterNavLink
          style={{ cursor: "pointer" }}
          key={ImageStatusType.DuplicateImage}
          onClick={() => filterOnClick(ImageStatusType.DuplicateImage)}
        >
          {formatImageStatusType(ImageStatusType.DuplicateImage)}
        </SearchFilterNavLink>
        <SearchFilterNavLink
          style={{ cursor: "pointer" }}
          key={ImageStatusType.CaseAssignmentPending}
          onClick={() => filterOnClick(ImageStatusType.CaseAssignmentPending)}
        >
          {formatImageStatusType(ImageStatusType.CaseAssignmentPending)}
        </SearchFilterNavLink>
        <SearchFilterNavLink
          style={{ cursor: "pointer" }}
          key={ImageStatusType.LabQcPending}
          onClick={() => filterOnClick(ImageStatusType.LabQcPending)}
        >
          {formatImageStatusType(ImageStatusType.LabQcPending)}
        </SearchFilterNavLink>
        <SearchFilterNavLink
          style={{ cursor: "pointer" }}
          key={ImageStatusType.IscQcPending}
          onClick={() => filterOnClick(ImageStatusType.IscQcPending)}
        >
          {formatImageStatusType(ImageStatusType.IscQcPending)}
        </SearchFilterNavLink>
        <SearchFilterNavLink
          style={{ cursor: "pointer" }}
          key={ImageStatusType.ReadyForReading}
          onClick={() => filterOnClick(ImageStatusType.ReadyForReading)}
        >
          {formatImageStatusType(ImageStatusType.ReadyForReading)}
        </SearchFilterNavLink>
        <SearchFilterNavLink
          style={{ cursor: "pointer" }}
          key={ImageStatusType.Complete}
          onClick={() => filterOnClick(ImageStatusType.Complete)}
        >
          {formatImageStatusType(ImageStatusType.Complete)}
        </SearchFilterNavLink>
      </ol>
      {statusFilter && (
        <ol>
          <hr />
          {statusFilter.map(filter => (
            <div key={filter}>{formatImageStatusType(filter)}</div>
          ))}
          <SearchFilterNavLink onClick={() => filterOnClick(null)}>
            clear <Icon name="cross" />
          </SearchFilterNavLink>
        </ol>
      )}
    </div>
  );
};

export enum SearchFilterName {
  Study,
  UploadDate,
  Status
}

export const SearchFilterNavLink = styled.li`
  border: none;
  background: "#fff";
  padding: 0 1rem;
  border-radius: 0;
  box-shadow: none;
  margin-right: 4px;
  border: 1px solid #dde0e6;
  border-width: 0;
  &:hover {
    background-color: rgba(179, 189, 204, 0.15);
  }
`;

export const SearchFilterNavLinkDiv = styled.div`
  border: none;
  background: "#fff";
  padding: 0 1rem;
  border-radius: 0;
  box-shadow: none;
  margin-right: 4px;
  border: 1px solid #dde0e6;
  border-width: 0;
  &:hover {
    background-color: rgba(179, 189, 204, 0.15);
  }
`;

const ImageSearchFilters = ({ isOpen }: ImageManagementSearchFiltersProps) => {
  const dispatch = useAppDispatch();

  const [selectedFilter, setSelectedFilter] = useState<SearchFilterName | null>(null);

  const closeDialog = () => {
    dispatch(closeImageFilterDialog());
  };

  const childControl = () => {
    switch (selectedFilter) {
      case SearchFilterName.Study:
        return <StudyFilterControl imageOrCase={"Image"} />;
      case SearchFilterName.UploadDate:
        return <UploadDateControl imageOrCase={"Image"} />;
      case SearchFilterName.Status:
        return <ImageStatusControl />;
      default:
        return <></>;
    }
  };

  const filterOnClick = (name: SearchFilterName | null) => {
    setSelectedFilter(name);
  };

  return (
    <Dialog isOpen={isOpen} onRequestClose={closeDialog} appElementId="root" width="600px">
      <DialogHeader title={"Search Filters"} closeDialog={closeDialog} />
      <DialogBody>
        <Box>
          {selectedFilter == null && (
            <div>
              <ol>
                <SearchFilterNavLink
                  style={{ cursor: "pointer" }}
                  onClick={() => filterOnClick(SearchFilterName.Study)}
                >
                  Study Name
                </SearchFilterNavLink>
                <SearchFilterNavLink
                  style={{ cursor: "pointer" }}
                  onClick={() => filterOnClick(SearchFilterName.UploadDate)}
                >
                  Upload Date
                </SearchFilterNavLink>
                <SearchFilterNavLink
                  style={{ cursor: "pointer" }}
                  onClick={() => filterOnClick(SearchFilterName.Status)}
                >
                  Status
                </SearchFilterNavLink>
              </ol>
            </div>
          )}
          {childControl()}
        </Box>
      </DialogBody>
      <DialogFooter>
        <Box display="flex" width="100%" justifyContent="space-between" mb={2}>
          <Box>
            {selectedFilter !== null && (
              <SearchFilterNavLinkDiv
                style={{ cursor: "pointer" }}
                onClick={() => filterOnClick(null)}
              >
                back
              </SearchFilterNavLinkDiv>
            )}
          </Box>
          <Box>
            <SearchFilterNavLinkDiv
              style={{ cursor: "pointer" }}
              onClick={() => dispatch(clearSearchFilter())}
            >
              clear
            </SearchFilterNavLinkDiv>
          </Box>
        </Box>
      </DialogFooter>
    </Dialog>
  );
};

export default ImageSearchFilters;
