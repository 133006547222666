import { prop } from "ramda";
import React from "react";

import {
  Box,
  Button,
  Callout,
  Dialog,
  Grouper,
  Intent,
  Label,
  Text,
  TextInput
} from "@blasterjs/core";

import ReasonForChange from "../components/ReasonForChange";

import { DialogBody, DialogFooter, DialogHeader } from "../components/DialogLayout";
import Select, { SelectOfType } from "../components/Select";

import { CaseStatus, formatCaseStatus, Image, ReaderStudyStats, userLabel } from "../models";
import { areDifferent } from "../utils";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  addReader,
  assignImage,
  CaseDialogMode,
  CaseForm,
  caseSearchReadersRequest,
  caseSuggestReadersRequest,
  changeCase,
  closeCaseDialog,
  createCaseRequest,
  dropReader,
  editCaseRequest,
  imagesSearch,
  unassignImage
} from "../slices/caseDialog";
import { RolePermissions } from "../permissions";

function formatReaderStats(stats: ReaderStudyStats): string {
  return `${userLabel(stats.reader)} (${stats.numReadCases}/${stats.numTotalCases} cases read)`;
}

const SelectReaders = Select as SelectOfType<ReaderStudyStats>;
const SelectImages = Select as SelectOfType<Image>;

const CaseDialog = () => {
  const dispatch = useAppDispatch();
  const loggedInUser = useAppSelector(state => state.auth.loggedInUser);

  const caseDialog = useAppSelector(state => state.caseDialog);

  const updateCaseField = <
    Key extends keyof CaseForm<CaseDialogMode>,
    PartialUpdate extends Partial<CaseForm<CaseDialogMode>[Key]>
  >(
    caseKey: Key,
    value: PartialUpdate
  ) => {
    dispatch(
      changeCase({
        ...caseDialog.histoCase.data,
        [caseKey]: {
          ...caseDialog.histoCase.data[caseKey],
          ...value
        }
      })
    );
  };

  const onSubjectIdChange = (key: keyof CaseForm<CaseDialogMode>) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const subjectId = e.currentTarget.value;
    dispatch(caseSuggestReadersRequest(subjectId));
    updateCaseField(key, { value: subjectId });
  };

  const updateCaseFieldWithValue = (key: keyof CaseForm<CaseDialogMode>) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => updateCaseField(key, { value: e.currentTarget.value });
  const updateCaseFieldWithReasonForChange = (key: keyof CaseForm<CaseDialogMode>) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => updateCaseField(key, { reasonForChange: e.currentTarget.value });
  const onReaderNameChange = (value: string) => {
    dispatch(caseSearchReadersRequest({ studyId: caseDialog.studyId, name: value }));
  };
  const onClickSuggestedReader = (reader: ReaderStudyStats) => () => onSelectReader(reader);
  const onSelectReader = (reader: ReaderStudyStats) => {
    dispatch(addReader(reader));
  };
  const onUnassignReader = (reader: ReaderStudyStats) => {
    dispatch(dropReader(reader));
  };
  const onImageNameChange = (value: string) => {
    dispatch(imagesSearch(value));
  };
  const onSelectImage = (image: Image) => {
    dispatch(assignImage(image));
  };
  const onUnassignImage = (image: Image) => {
    dispatch(unassignImage(image));
  };
  const closeDialog = () => {
    dispatch(closeCaseDialog());
  };
  const onSave = () => {
    dispatch(
      caseDialog.mode === CaseDialogMode.OpenForCreate ? createCaseRequest() : editCaseRequest()
    );
  };
  const caseStatus = caseDialog.savedHistoCase && caseDialog.savedHistoCase.caseWithStatus.status;
  const imageAssignmentDisabled =
    caseDialog.mode !== CaseDialogMode.OpenForCreate &&
    !(
      caseStatus == CaseStatus.PendingLabQC ||
      caseStatus == CaseStatus.PendingQC ||
      caseStatus == CaseStatus.OnHold
    );
  const disabledText = imageAssignmentDisabled
    ? `Images can only be assigned to cases in ${formatCaseStatus(CaseStatus.PendingQC)}`
    : undefined;
  const errorText =
    "errorMessage" in caseDialog.histoCase ? (
      <Box mb={2}>
        <Callout intent={Intent.DANGER}>
          <Text>{caseDialog.histoCase.errorMessage}</Text>
        </Callout>
      </Box>
    ) : null;
  const readerSuggestionText =
    "resource" in caseDialog.readerSuggestions && caseDialog.readerSuggestions.resource.length ? (
      <Text>
        <Text as="i">
          Suggested reader(s) for subject {caseDialog.histoCase.data.subjectId.value}
        </Text>
        :
        <Grouper>
          {caseDialog.readerSuggestions.resource.map(readerStats => (
            <Button key={readerStats.reader.id} onClick={onClickSuggestedReader(readerStats)}>
              {userLabel(readerStats.reader)}
            </Button>
          ))}
        </Grouper>
      </Text>
    ) : null;

  function fieldSaveableCase(
    fieldName: "procId" | "histoProcedureId" | "subjectId" | "siteId" | "visitId"
  ): boolean {
    const isNewCase: boolean = caseDialog.savedHistoCase == null;
    const newValue = caseDialog.histoCase.data[fieldName].value || "";
    const oldValue = caseDialog.savedHistoCase?.caseWithStatus[fieldName] || "";
    const rfc =
      ("reasonForChange" in caseDialog.histoCase.data[fieldName] &&
        (caseDialog.histoCase.data[fieldName] as any).reasonForChange) ||
      "";
    const updateSavable = oldValue == newValue || (oldValue != newValue && rfc.length > 0);
    return isNewCase || updateSavable;
  }

  function fieldSaveableCaseMany(fieldName: "images" | "readers"): boolean {
    const isNewCase: boolean = caseDialog.savedHistoCase == null;
    const newValue = caseDialog.histoCase.data[fieldName].value || [];
    const oldValue =
      (caseDialog &&
        caseDialog.savedHistoCase &&
        fieldName in caseDialog.savedHistoCase &&
        caseDialog.savedHistoCase[fieldName]) ||
      [];
    const rfc =
      ("reasonForChange" in caseDialog.histoCase.data[fieldName] &&
        (caseDialog.histoCase.data[fieldName] as any).reasonForChange) ||
      "";
    const updateSavable = oldValue == newValue || (oldValue != newValue && rfc.length > 0);
    return isNewCase || updateSavable;
  }

  const disableSave: boolean =
    !fieldSaveableCase("procId") ||
    !fieldSaveableCase("histoProcedureId") ||
    !fieldSaveableCase("subjectId") ||
    !fieldSaveableCase("siteId") ||
    !fieldSaveableCase("visitId") ||
    !fieldSaveableCaseMany("images") ||
    !fieldSaveableCaseMany("readers");

  return (
    <Dialog
      isOpen={
        caseDialog.mode !== CaseDialogMode.Closed &&
        // Avoid flicker by waiting to open dialog until case has loaded
        !("isPending" in caseDialog.histoCase)
      }
      onRequestClose={closeDialog}
      appElementId="root"
      width="600px"
    >
      <DialogHeader
        title={(caseDialog.mode === CaseDialogMode.OpenForCreate ? "Create" : "Edit") + " Case"}
        closeDialog={closeDialog}
      />
      <DialogBody>
        {errorText}
        <Box>
          <Label>
            Endo Procedure ID
            <TextInput
              placeholder="Endo Procedure ID"
              mb={2}
              value={caseDialog.histoCase.data.procId.value}
              onChange={updateCaseFieldWithValue("procId")}
            />
          </Label>
        </Box>
        {caseDialog.mode === CaseDialogMode.OpenForEdit &&
        caseDialog.savedHistoCase?.caseWithStatus.procId !==
          caseDialog.histoCase.data.procId.value ? (
          <Box>
            <ReasonForChange
              rkey="procId_reason"
              mb={2}
              value={
                "reasonForChange" in caseDialog.histoCase.data.procId
                  ? caseDialog.histoCase.data.procId.reasonForChange
                  : ""
              }
              onChange={updateCaseFieldWithReasonForChange("procId")}
            />
          </Box>
        ) : null}
        {caseDialog.mode === CaseDialogMode.OpenForCreate ? (
          <></>
        ) : (
          <>
            <Box>
              <Label>
                Histo Procedure ID
                <TextInput
                  placeholder="Histo Procedure ID"
                  mb={2}
                  value={caseDialog.histoCase.data.histoProcedureId.value}
                  onChange={updateCaseFieldWithValue("histoProcedureId")}
                />
              </Label>
            </Box>
            {caseDialog.mode === CaseDialogMode.OpenForEdit &&
            caseDialog.savedHistoCase?.caseWithStatus.histoProcedureId !==
              caseDialog.histoCase.data.histoProcedureId.value ? (
              <Box>
                <ReasonForChange
                  rkey="histoProcedureId_reason"
                  mb={2}
                  value={
                    "reasonForChange" in caseDialog.histoCase.data.histoProcedureId
                      ? caseDialog.histoCase.data.histoProcedureId.reasonForChange
                      : ""
                  }
                  onChange={updateCaseFieldWithReasonForChange("histoProcedureId")}
                />
              </Box>
            ) : null}
          </>
        )}
        <Box>
          <Label>
            Subject ID
            <br />
            <TextInput
              placeholder="Subject ID"
              mb={2}
              value={caseDialog.histoCase.data.subjectId.value}
              onChange={onSubjectIdChange("subjectId")}
            />
          </Label>
        </Box>
        {caseDialog.mode === CaseDialogMode.OpenForEdit &&
        caseDialog.savedHistoCase?.caseWithStatus.subjectId !==
          caseDialog.histoCase.data.subjectId.value ? (
          <Box>
            <ReasonForChange
              rkey="subjectId_reason"
              mb={2}
              value={
                "reasonForChange" in caseDialog.histoCase.data.subjectId
                  ? caseDialog.histoCase.data.subjectId.reasonForChange
                  : ""
              }
              onChange={updateCaseFieldWithReasonForChange("subjectId")}
            />
          </Box>
        ) : null}
        <Box>
          <Label>
            Site ID
            <br />
            <TextInput
              placeholder="Site ID"
              mb={2}
              value={caseDialog.histoCase.data.siteId.value || ""}
              onChange={updateCaseFieldWithValue("siteId")}
            />
          </Label>
        </Box>
        {caseDialog.mode === CaseDialogMode.OpenForEdit &&
        caseDialog.savedHistoCase?.caseWithStatus.siteId !==
          caseDialog.histoCase.data.siteId.value ? (
          <Box>
            <ReasonForChange
              rkey="siteId_reason"
              mb={2}
              value={
                "reasonForChange" in caseDialog.histoCase.data.siteId
                  ? caseDialog.histoCase.data.siteId.reasonForChange
                  : ""
              }
              onChange={updateCaseFieldWithReasonForChange("siteId")}
            />
          </Box>
        ) : null}
        <Box>
          <Label>
            Visit ID
            <br />
            <TextInput
              placeholder="Visit ID"
              mb={2}
              value={caseDialog.histoCase.data.visitId.value}
              onChange={updateCaseFieldWithValue("visitId")}
            />
          </Label>
        </Box>
        {caseDialog.mode === CaseDialogMode.OpenForEdit &&
        caseDialog.savedHistoCase?.caseWithStatus.visitId !==
          caseDialog.histoCase.data.visitId.value ? (
          <Box>
            <ReasonForChange
              rkey="visitId_reason"
              mb={2}
              value={
                "reasonForChange" in caseDialog.histoCase.data.visitId
                  ? caseDialog.histoCase.data.visitId.reasonForChange
                  : ""
              }
              onChange={updateCaseFieldWithReasonForChange("visitId")}
            />
          </Box>
        ) : null}
        <hr />
        <Box mb={2}>{readerSuggestionText}</Box>
        <Box display="block" justifyContent="space-between" mb={2}>
          <Box style={{ width: "100%" }}>
            <Label>
              Assign Readers
              <SelectReaders
                disabled={
                  !(
                    "resource" in loggedInUser &&
                    loggedInUser.resource.can([RolePermissions.AP_CaseViewer_ManualCRassignment])
                  )
                }
                placeholder={"Enter user’s name"}
                searchText={caseDialog.readersSearchText}
                searchResults={
                  "resource" in caseDialog.readersSearchResults
                    ? caseDialog.readersSearchResults.resource
                    : []
                }
                onSelect={onSelectReader}
                onChangeSearchText={onReaderNameChange}
                onDeselect={onUnassignReader}
                selectedItems={
                  caseDialog.histoCase.data ? caseDialog.histoCase.data.readers.value : []
                }
                isLoading={
                  "isPending" in caseDialog.readersSearchResults
                    ? caseDialog.readersSearchResults.isPending
                    : false
                }
                format={formatReaderStats}
              />
            </Label>
          </Box>
          {caseDialog.mode === CaseDialogMode.OpenForEdit &&
          caseDialog.savedHistoCase &&
          areDifferent(
            caseDialog.savedHistoCase.readers.map(readerStats => readerStats.reader.id),
            caseDialog.histoCase.data.readers.value.map(readerStats => readerStats.reader.id)
          ) ? (
            <Box>
              <ReasonForChange
                rkey="readers_reason"
                mb={2}
                value={
                  "reasonForChange" in caseDialog.histoCase.data.readers
                    ? caseDialog.histoCase.data.readers.reasonForChange
                    : ""
                }
                onChange={updateCaseFieldWithReasonForChange("readers")}
              />
            </Box>
          ) : null}
        </Box>
        <hr />
        <Box display="block" justifyContent="space-between" mb={2}>
          <Box width="100%">
            <Label>
              Assign Images
              <SelectImages
                placeholder={"Enter image file name"}
                searchText={caseDialog.imagesSearchText}
                searchResults={
                  "resource" in caseDialog.imagesSearchResults
                    ? caseDialog.imagesSearchResults.resource
                    : []
                }
                onSelect={onSelectImage}
                onChangeSearchText={onImageNameChange}
                onDeselect={onUnassignImage}
                selectedItems={
                  caseDialog.histoCase.data ? caseDialog.histoCase.data.images.value : []
                }
                isLoading={
                  "isPending" in caseDialog.imagesSearchResults &&
                  caseDialog.imagesSearchResults.isPending
                }
                format={prop("name")}
                disabled={imageAssignmentDisabled}
                disabledText={disabledText}
              />
            </Label>
          </Box>
          {caseDialog.mode === CaseDialogMode.OpenForEdit &&
          caseDialog.savedHistoCase &&
          areDifferent(
            caseDialog.savedHistoCase.images.map(image => image.id),
            caseDialog.histoCase.data.images.value.map(image => image.id)
          ) ? (
            <Box>
              <ReasonForChange
                rkey="images_reason"
                mb={2}
                value={
                  "reasonForChange" in caseDialog.histoCase.data.images
                    ? caseDialog.histoCase.data.images.reasonForChange
                    : ""
                }
                onChange={updateCaseFieldWithReasonForChange("images")}
              />
            </Box>
          ) : null}
        </Box>
      </DialogBody>
      <DialogFooter>
        <Box>
          <Button
            intent="primary"
            appearance="prominent"
            onClick={onSave}
            isLoading={"isSaving" in caseDialog.histoCase}
            disabled={disableSave}
          >
            Save
          </Button>
        </Box>
        <Box>
          <Button onClick={closeDialog}>Cancel</Button>
        </Box>
      </DialogFooter>
    </Dialog>
  );
};

export default CaseDialog;
