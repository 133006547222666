import React, { useEffect, useState } from "react";

import { Box, Button, Dialog, TextInput } from "@blasterjs/core";

import { DialogBody, DialogFooter, DialogHeader } from "./DialogLayout";

import { useAppDispatch, useAppSelector } from "../hooks";
import {
  DialogMode,
  closeImageManagementEditTableCell,
  refreshCaseRecordRow,
  refreshImageRecordRow,
  saveEditCaseColumnValue,
  saveEditColumnValue,
  loadVisitIds,
  ImageTableColumns
} from "../slices/ImageManagementEditTableCell";
import { ApiResponse } from "../models";

const ImageManagementEditTableCellDialog = () => {
  const dispatch = useAppDispatch();

  const dialogMode = useAppSelector(state => state.imageManagementEditTableCell.mode);
  const modalParams = useAppSelector(state => state.imageManagementEditTableCell.params);

  const imageId =
    modalParams && modalParams.row && "id" in modalParams.row ? modalParams.row.id : null;

  const caseId =
    modalParams && modalParams.row && "caseId" in modalParams.row ? modalParams.row.caseId : null;

  const closeDialog = () => {
    dispatch(closeImageManagementEditTableCell());
  };

  return (
    <Dialog
      isOpen={dialogMode === DialogMode.OpenForEdit}
      onRequestClose={closeDialog}
      appElementId="root"
      width="600px"
    >
      {modalParams &&
        modalParams.editingColumn &&
        (modalParams.editingColumn == ImageTableColumns.LabVisitID ||
        modalParams.editingColumn == ImageTableColumns.VisitID ? (
          <ComboBoxControl
            id={imageId || caseId || ""}
            tab={imageId ? "images" : "cases"}
            columnName={modalParams.editingColumn}
            columnValue={modalParams.columnValue || ""}
            errorMessage={modalParams.errorMessage}
          />
        ) : (
          <InputBoxControl
            id={imageId || caseId || ""}
            tab={imageId ? "images" : "cases"}
            columnName={modalParams.editingColumn}
            columnValue={modalParams.columnValue || ""}
            errorMessage={modalParams.errorMessage}
          />
        ))}
    </Dialog>
  );
};

export default ImageManagementEditTableCellDialog;

interface EditColumnParams {
  readonly id: string;
  readonly tab: "images" | "cases";
  readonly columnName: string;
  readonly columnValue: string;
  readonly errorMessage: string | null;
}

const InputBoxControl = (params: EditColumnParams) => {
  const dispatch = useAppDispatch();

  const [newColumnValue, setNewColumnValue] = useState(params.columnValue);
  const [reasonForChange, setReasonForChange] = useState("");

  const closeDialog = () => {
    dispatch(closeImageManagementEditTableCell());
  };

  const onSave = () => {
    if (params.tab == "images") {
      dispatch(
        saveEditColumnValue({
          imageId: params.id,
          tabName: params.tab,
          editingColumnName: params.columnName,
          newColumnValue: newColumnValue,
          reasonForChange: reasonForChange
        })
      )
        .then(p => {
          const pa = p as any;
          if (!pa.error) {
            const r: ApiResponse = (p.payload as any) as ApiResponse;
            if (r && r.success) {
              dispatch(closeImageManagementEditTableCell());
            }
          }
        })
        .then(() => dispatch(refreshImageRecordRow(params.id)));
    } else {
      dispatch(
        saveEditCaseColumnValue({
          imageId: params.id,
          tabName: params.tab,
          editingColumnName: params.columnName,
          newColumnValue: newColumnValue,
          reasonForChange: reasonForChange
        })
      )
        .then(p => {
          const pa = p as any;
          if (!pa.error) {
            const r: ApiResponse = (p.payload as any) as ApiResponse;
            if (r && r.success) {
              dispatch(closeImageManagementEditTableCell());
            }
          }
        })
        .then(() => dispatch(refreshCaseRecordRow(params.id)));
    }
  };

  const reasonForChangeOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewColumnValue(e.currentTarget.value);
  };

  const title = `Edit Column: ${params.columnName}`;
  const buttonLabel = "Confirm";

  return (
    <>
      <DialogHeader title={title} closeDialog={closeDialog} />
      <DialogBody>
        <Box display="block" style={{ color: "red" }} justifyContent="space-between" mb={2}>
          {params.errorMessage}
        </Box>
        <Box display="block" justifyContent="space-between" mb={2}>
          <TextInput
            value={newColumnValue}
            onChange={reasonForChangeOnChange}
            style={{
              paddingLeft: "1rem"
            }}
          />
        </Box>
        {params.columnValue.length > 0 && (
          <Box display="block" justifyContent="space-between" mb={2}>
            Reason for Change
            <Box>
              <TextInput
                value={reasonForChange}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setReasonForChange(e.currentTarget.value);
                }}
                style={{
                  paddingLeft: "1rem"
                }}
              />
            </Box>
          </Box>
        )}
      </DialogBody>
      <DialogFooter>
        <Box>
          <Button
            intent="primary"
            appearance="prominent"
            disabled={
              params.columnValue
                ? reasonForChange == "" || params.columnValue == newColumnValue
                : newColumnValue.length == 0
            }
            onClick={onSave}
          >
            {buttonLabel}
          </Button>
        </Box>
        <Box>
          <Button onClick={closeDialog}>Cancel</Button>
        </Box>
      </DialogFooter>
    </>
  );
};

const ComboBoxControl = (params: EditColumnParams) => {
  const dispatch = useAppDispatch();

  const [newColumnValue, setNewColumnValue] = useState(params.columnValue);
  const [reasonForChange, setReasonForChange] = useState("");

  const valueOptions = useAppSelector(state => state.imageManagementEditTableCell.valueOptions);

  const modalParams = useAppSelector(state => state.imageManagementEditTableCell.params);

  useEffect(() => {
    if (
      modalParams != null &&
      modalParams.row != null &&
      "studyId" in modalParams.row &&
      modalParams.row.studyId != null
    ) {
      dispatch(loadVisitIds(modalParams.row.studyId));
    }
  }, [modalParams]);

  const closeDialog = () => {
    dispatch(closeImageManagementEditTableCell());
  };

  const onSave = () => {
    if (params.tab == "images") {
      dispatch(
        saveEditColumnValue({
          imageId: params.id,
          tabName: params.tab,
          editingColumnName: params.columnName,
          newColumnValue: newColumnValue,
          reasonForChange: reasonForChange
        })
      )
        .then(p => {
          const pa = p as any;
          if (!pa.error) {
            const r: ApiResponse = (p.payload as any) as ApiResponse;
            if (r && r.success) {
              dispatch(closeImageManagementEditTableCell());
            }
          }
        })
        .then(() => dispatch(refreshImageRecordRow(params.id)));
    } else {
      dispatch(
        saveEditCaseColumnValue({
          imageId: params.id,
          tabName: params.tab,
          editingColumnName: params.columnName,
          newColumnValue: newColumnValue,
          reasonForChange: reasonForChange
        })
      )
        .then(p => {
          const pa = p as any;
          if (!pa.error) {
            const r: ApiResponse = (p.payload as any) as ApiResponse;
            if (r && r.success) {
              dispatch(closeImageManagementEditTableCell());
            }
          }
        })
        .then(() => dispatch(refreshCaseRecordRow(params.id)));
    }
  };

  const title = `Edit Column: ${params.columnName}`;
  const buttonLabel = "Confirm";

  return (
    <>
      <DialogHeader title={title} closeDialog={closeDialog} />
      <DialogBody>
        <Box display="block" style={{ color: "red" }} justifyContent="space-between" mb={2}>
          {params.errorMessage}
        </Box>
        <Box display="block" justifyContent="space-between" mb={2}>
          {valueOptions && valueOptions.length > 0 ? (
            <span>
              Select <b>{params.columnName}</b> from the Study Configuration
            </span>
          ) : null}
          {valueOptions && valueOptions.length > 0 ? (
            <select onChange={e => setNewColumnValue(e.currentTarget.value)} value={newColumnValue}>
              <option key="" value="">
                Select a {params.columnName}
              </option>
              {valueOptions.map(choice => {
                return (
                  <option key={choice.choiceKey} value={choice.choiceKey}>
                    {choice.value}
                  </option>
                );
              })}
            </select>
          ) : (
            "No options configured."
          )}
        </Box>
        {params.columnValue.length > 0 && (
          <Box display="block" justifyContent="space-between" mb={2}>
            Reason for Change
            <Box>
              <TextInput
                value={reasonForChange}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setReasonForChange(e.currentTarget.value);
                }}
                style={{
                  paddingLeft: "1rem"
                }}
              />
            </Box>
          </Box>
        )}
      </DialogBody>
      <DialogFooter>
        <Box>
          <Button
            intent="primary"
            appearance="prominent"
            disabled={
              params.columnValue
                ? reasonForChange == "" || params.columnValue == newColumnValue
                : newColumnValue.length == 0
            }
            onClick={onSave}
          >
            {buttonLabel}
          </Button>
        </Box>
        <Box>
          <Button onClick={closeDialog}>Cancel</Button>
        </Box>
      </DialogFooter>
    </>
  );
};
