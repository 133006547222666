import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CaseStatus, Roles, UUID } from "../models";
import { Resource } from "../types";
import { archiveCaseApi, putCaseOnHoldApi, removeCaseHoldApi, revertCaseStatusApi } from "../api";
import { ModalMessage } from "./ImageManagementImageDialogParent";

export enum DialogMode {
  OpenForEdit = "OPEN_FOR_EDIT",
  Closed = "CLOSED"
}

/*
operaions (cases)
   - deselect all
   - revert status
   - put on hold
   - download image (single select)
   - archive
   more menu:
    inputs:
      caseId or caseIds (array)
      - revert status
        - list of statuses in DDL
        - reason for change inputbox
      - remove hold
        - reason for change inputbox
      - put on hold
        - reason for change inputbox
      - view images
      - download
        - button to download images for case
      - archive
        - reason for change inputbox
*/
export enum CaseRowOptions {
  RevertStatus = "RevertStatus",
  PutOnHold = "PutOnHold",
  RemoveHold = "RemoveHold",
  ViewImages = "ViewImages",
  Download = "Download",
  Archive = "Archive"
}

export type ImageManagementDialogParentType = string;

export interface ImageManagementCaseDialogParentState {
  readonly mode: DialogMode;
  readonly modalMessage: ModalMessage | null;
  readonly modalParams: OpenImageManagementCaseDialogParentParams | null;
  readonly availableRoles: Resource<Roles>;
  readonly selectedRole: UUID | null;
}

export const initialState: ImageManagementCaseDialogParentState = {
  mode: DialogMode.Closed,
  modalMessage: null,
  modalParams: null,
  availableRoles: { isPending: false },
  selectedRole: null
};

export type CaseDialogParentSingleSelectionParams = {
  caseRowOption: CaseRowOptions;
  caseId: UUID | null;
  contextInfo: string | null;
};

export type CaseDialogParentMultiSelectionParams = {
  caseRowOption: CaseRowOptions;
};

export type OpenImageManagementCaseDialogParentParams =
  | CaseDialogParentSingleSelectionParams
  | CaseDialogParentMultiSelectionParams;

// thunks
export const openImageManagementCaseDialogParent = createAsyncThunk(
  "imageManagementCaseDialogParent/openImageManagementDialogParent",
  (params: OpenImageManagementCaseDialogParentParams, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(setOpenImageManagementCaseDialogParent(params));
  }
);

export interface ArchiveCaseParams {
  caseId: UUID;
  reasonForChange: string;
}
export const archiveCase = createAsyncThunk(
  "imageManagementCaseDialogParent/archiveCase",
  async (params: ArchiveCaseParams) => {
    await archiveCaseApi(params.caseId, params.reasonForChange);
  }
);

export interface RevertCaseStatusParams {
  caseId: UUID;
  caseStatus: CaseStatus;
  reasonForChange: string;
}
export const revertCaseStatus = createAsyncThunk(
  "imageManagementCaseDialogParent/revertCaseStatus",
  async (params: RevertCaseStatusParams) => {
    const response = await revertCaseStatusApi(params.caseId, {
      value: params.caseStatus,
      reasonForChange: params.reasonForChange || undefined
    });
    return response;
  }
);

export interface PutCaseOnHoldParams {
  caseId: UUID;
  reasonForChange: string;
}

export const putCaseOnHold = createAsyncThunk(
  "imageManagementCaseDialogParent/putCaseOnHold",
  async (params: PutCaseOnHoldParams) => {
    const response = await putCaseOnHoldApi(params.caseId, params.reasonForChange);
    return response;
  }
);

export const removeCaseHold = createAsyncThunk(
  "imageManagementCaseDialogParent/removeCaseHold",
  async (params: PutCaseOnHoldParams) => {
    const response = await removeCaseHoldApi(params.caseId, params.reasonForChange);
    return response;
  }
);

// slice

export const imageManagementCaseDialogParentSlice = createSlice({
  name: "imageManagementCaseDialogParent",
  initialState: initialState,
  reducers: {
    setOpenImageManagementCaseDialogParent: (state, action) => {
      return {
        ...initialState,
        mode: DialogMode.OpenForEdit,
        modalParams: action.payload
      };
    },
    closeOpenImageManagementCaseDialogParent: state => {
      state.mode = DialogMode.Closed;
    },
    setModalMessaging: (state, action: PayloadAction<ModalMessage>) => {
      state.modalMessage = {
        success: action.payload.success,
        message: action.payload.message
      };
    },
    clearModalMessaging: state => {
      state.modalMessage = null;
    }
  },
  extraReducers: builder => {
    builder.addCase(revertCaseStatus.rejected, (state, action) => {
      state.modalMessage = {
        success: false,
        message: action.error.message || ""
      };
    });
    builder.addCase(putCaseOnHold.fulfilled, state => {
      state.modalMessage = {
        success: true,
        message: "The case has been put on hold successfully"
      };
    });
    builder.addCase(putCaseOnHold.rejected, (state, action) => {
      state.modalMessage = {
        success: false,
        message: action.error.message || ""
      };
    });
    builder.addCase(removeCaseHold.fulfilled, state => {
      state.modalMessage = {
        success: true,
        message: "The hold has been removed from the case successfully"
      };
    });
    builder.addCase(removeCaseHold.rejected, (state, action) => {
      state.modalMessage = {
        success: false,
        message: action.error.message || ""
      };
    });
    builder.addCase(archiveCase.fulfilled, state => {
      state.modalMessage = {
        success: true,
        message: "The case has been archived successfully"
      };
    });
    builder.addCase(archiveCase.rejected, (state, action) => {
      state.modalMessage = {
        success: false,
        message: action.error.message || ""
      };
    });
  }
});

export const {
  setOpenImageManagementCaseDialogParent,
  closeOpenImageManagementCaseDialogParent,
  setModalMessaging,
  clearModalMessaging
} = imageManagementCaseDialogParentSlice.actions;

export default imageManagementCaseDialogParentSlice.reducer;
