import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { Box, Button, Select as BlasterSelect } from "@blasterjs/core";
import {
  addSelectedUser,
  addStudyAccessRequest,
  clearSelectedUser,
  searchUsersRequest,
  studyFetchRequest,
  usersInStudyFetch,
  setNewStudyAccessFormRoleId,
  removeStudyAccessRequest,
  resetNewUserSearchForm
} from "../slices/studyConfiguration";
import { rolesFetch } from "../slices/permissions";
import { StyledDataTable, TableContainer, TableFilters } from "../components/Table";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { Role, roleLabel, User, UserInStudy, userLabel } from "../models";
import Page, { PageBody, PageHeader, PageHeading, PageTabs } from "../components/Page";
import Select, { SelectOfType } from "../components/Select";

const SelectUsers = Select as SelectOfType<User>;

interface EditStudyUsersProps {
  readonly studyId: string;
}

interface AddingUserDetails {
  readonly username: string | null;
  readonly roleName: string | null;
}

const StudyConfigurationEditUsers = ({ studyId }: EditStudyUsersProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(studyFetchRequest(studyId));
    dispatch(rolesFetch());
    dispatch(usersInStudyFetch({ studyId: studyId, roleIdFilter: null }));
  }, [studyId]);

  const [isAddUserConfirmationDialogOpen, setAddUserConfirmationDialogOpen] = useState(false);
  const [isRemoveAccessConfirmationDialogOpen, setRemoveAccessConfirmationDialogOpen] = useState(
    false
  );
  const [removeAccessUserId, setRemoveAccessUserId] = useState("");
  const [removeAccessRoleId, setRemoveAccessRoleId] = useState<string | null>("");
  const [removeAccessUsername, setRemoveAccessUsername] = useState("");

  const [addingUserDetails, setAddingUserDetails] = useState<AddingUserDetails>({
    username: null,
    roleName: null
  });

  const roles = useAppSelector(state => state.permissions.roles);
  const usersInStudy = useAppSelector(state => state.studyConfiguration.usersInStudy);
  const newUserSearchForm = useAppSelector(state => state.studyConfiguration.newUserSearchForm);
  const existingStudy = useAppSelector(state => state.studyConfiguration.existingStudy);

  const studyName = `'${existingStudy?.study.name}'`;

  const onRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const roleId = e.currentTarget.value;
    dispatch(setNewStudyAccessFormRoleId(roleId));

    const role: Role | undefined =
      "resource" in roles ? roles.resource.find(r => r.id === roleId) : undefined;

    if (role) {
      setAddingUserDetails({
        ...addingUserDetails,
        roleName: role.name
      });
    }
  };

  const onRoleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const roleId = e.currentTarget.value;
    const role: Role | undefined =
      "resource" in roles ? roles.resource.find(r => r.id == roleId) : undefined;

    if (role) {
      dispatch(usersInStudyFetch({ studyId: studyId, roleIdFilter: role.id }));
    } else {
      dispatch(usersInStudyFetch({ studyId: studyId, roleIdFilter: null }));
    }
  };

  const addUserToStudyClick = () => {
    setAddUserConfirmationDialogOpen(true);
  };

  // selectUser events
  const onUserNameChange = (value: string) => {
    const excludedUsers =
      "resource" in usersInStudy ? usersInStudy.resource.map(user => user.id) : [];
    dispatch(searchUsersRequest({ name: value, excludedUserIds: excludedUsers }));
  };
  const onSelectUser = (user: User) => {
    dispatch(addSelectedUser(user));

    setAddingUserDetails({
      ...addingUserDetails,
      username: user.username
    });
  };
  const onDeselectUser = () => {
    dispatch(clearSelectedUser());
  };

  const addUserToStudyConfirmationClick = () => {
    setAddUserConfirmationDialogOpen(false);
    dispatch(addStudyAccessRequest(studyId)).then(() =>
      dispatch(usersInStudyFetch({ studyId: studyId, roleIdFilter: null }))
    );
    dispatch(resetNewUserSearchForm());
  };

  const onCancelConfirmationDialog = () => {
    setAddUserConfirmationDialogOpen(false);
  };

  const addUserToStudyConfirmTransitionDialog = (
    <ConfirmationDialog
      title="Confirm Study Access"
      message={`${addingUserDetails.username} will be assigned as a ${addingUserDetails.roleName} to ${studyName}`}
      isOpen={isAddUserConfirmationDialogOpen}
      onConfirm={addUserToStudyConfirmationClick}
      onCancel={onCancelConfirmationDialog}
    />
  );

  const removeStudyAccessClick = (user: UserInStudy) => () => {
    setRemoveAccessConfirmationDialogOpen(true);
    setRemoveAccessUserId(user.id);
    setRemoveAccessRoleId(user.roleId);
    setRemoveAccessUsername(user.username);
  };

  const removeStudyAccessConfirmationClick = () => {
    const userId = removeAccessUserId;
    const roleId = removeAccessRoleId;
    dispatch(removeStudyAccessRequest({ userId, studyId, roleId })).then(() =>
      dispatch(usersInStudyFetch({ studyId: studyId, roleIdFilter: null }))
    );
    setRemoveAccessConfirmationDialogOpen(false);
  };

  const onCancelRemoveStudyAccessConfirmationDialog = () => {
    setRemoveAccessConfirmationDialogOpen(false);
  };

  const removeStudyAccessConfirmTransitionDialog = (
    <ConfirmationDialog
      title="Are you sure you want to remove study access?"
      message={`${removeAccessUsername} will no longer have access to ${studyName}`}
      isOpen={isRemoveAccessConfirmationDialogOpen}
      onConfirm={removeStudyAccessConfirmationClick}
      onCancel={onCancelRemoveStudyAccessConfirmationDialog}
    />
  );

  const columns: ReadonlyArray<object> = [
    {
      cell: (user: UserInStudy) => (
        <div>
          User: {user.username} <br />
          <span style={{ fontSize: "1.4rem" }}>
            Role Type: {user.roleName} | Organization: {user.organizationName}
          </span>
        </div>
      )
    },
    {
      right: true,
      grow: 0,
      cell: (user: UserInStudy) => (
        <Button
          isLoading={false}
          intent="primary"
          appearance="prominent"
          disabled={false}
          onClick={removeStudyAccessClick(user)}
        >
          Remove Access
        </Button>
      )
    }
  ];

  return (
    <Page>
      <Box style={{ padding: "0 2rem 4rem" }}>
        <PageHeader>
          <PageHeading>{!studyId || studyId === "new" ? "Create Study" : "Edit Study"}</PageHeading>
        </PageHeader>
        <PageTabs
          links={[
            {
              to: `/studies/${studyId}/configure/settings`,
              label: "Settings"
            },
            {
              to: `/studies/${studyId}/configure/associate-users`,
              label: "Users"
            }
          ]}
        />
        <PageBody>
          <TableContainer>
            <TableFilters>
              <Box display="flex" width="100%">
                <SelectUsers
                  placeholder={"Search Users"}
                  searchText={newUserSearchForm.usersSearchText}
                  searchResults={
                    "resource" in newUserSearchForm.usersSearchResults
                      ? newUserSearchForm.usersSearchResults.resource
                      : []
                  }
                  onSelect={onSelectUser}
                  onChangeSearchText={onUserNameChange}
                  onDeselect={onDeselectUser}
                  selectedItems={newUserSearchForm.selectedUsers}
                  isLoading={
                    "isPending" in newUserSearchForm.usersSearchResults &&
                    newUserSearchForm.usersSearchResults.isPending
                  }
                  format={userLabel}
                />
                <Box paddingLeft="10px">
                  <BlasterSelect
                    disabled={false}
                    onChange={onRoleChange}
                    value={newUserSearchForm.selectedRoleId || ""}
                  >
                    <option key="" value="">
                      Select role...
                    </option>
                    {"resource" in roles &&
                      roles.resource.map(role => {
                        return (
                          <option key={role.id} value={role.id}>
                            {roleLabel(role)}
                          </option>
                        );
                      })}
                  </BlasterSelect>
                </Box>
                <Box paddingLeft="10px">
                  <Button
                    isLoading={false}
                    intent="primary"
                    appearance="prominent"
                    disabled={newUserSearchForm.disableAddUserButton}
                    onClick={addUserToStudyClick}
                  >
                    + Add
                  </Button>
                </Box>
                <Box marginLeft="auto">
                  <BlasterSelect disabled={false} onChange={onRoleFilterChange}>
                    <option key="" value="">
                      Filter by Role
                    </option>
                    {"resource" in roles &&
                      roles.resource.map(role => (
                        <option key={role.id} value={role.id}>
                          {roleLabel(role)}
                        </option>
                      ))}
                  </BlasterSelect>
                </Box>
              </Box>
              {addUserToStudyConfirmTransitionDialog}
            </TableFilters>
            {removeStudyAccessConfirmTransitionDialog}
            {"resource" in usersInStudy ? (
              <StyledDataTable
                columns={columns}
                data={usersInStudy.resource}
                highlightOnHover={false}
                pointerOnHover={false}
                defaultSortField="username"
                className="data-table"
                noHeader={true}
                pagination={false}
              />
            ) : null}
          </TableContainer>
        </PageBody>
      </Box>
    </Page>
  );
};

export default StudyConfigurationEditUsers;
