import { Button, Select, Label, Text, Box, TextArea, Heading, Radio } from "@blasterjs/core";
import React, { useState, Dispatch, SetStateAction } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle as solidCircle, faX } from "@fortawesome/free-solid-svg-icons";
import { faCircle as emptyCircle } from "@fortawesome/free-regular-svg-icons";
import { useAppDispatch } from "../hooks";
import {
  UUID,
  QueryObjectType,
  QueryMetadataReason,
  Role,
  roleLabel,
  QueryRecordCategory,
  RoleName
} from "../models";
import { openQuery } from "../slices/queryDetails";
import FollowUpDatePicker from "./FollowUpDatePicker";
import Timestamp from "./Timestamp";
import { getDaysFromDateSelected } from "../utils";
import { LoggedInUser, RolePermissions } from "../permissions";

interface CreateQueryFlowProps {
  readonly loggedInUser: LoggedInUser;
  createQuery: boolean;
  setCreateQuery: Dispatch<SetStateAction<boolean>>;
  reasons: Array<QueryMetadataReason>;
  roles: Array<Role>;
  queryType: QueryObjectType;
  queryTypeId: UUID;
  studyId: UUID | null;
  caseId: UUID;
}

const CreateQueryFlow = ({
  loggedInUser,
  createQuery,
  setCreateQuery,
  reasons,
  roles,
  queryType,
  queryTypeId,
  studyId,
  caseId
}: CreateQueryFlowProps) => {
  const INITIAL_STEP = 1;
  const TOTAL_STEPS = 4;

  const dispatch = useAppDispatch();

  // TODO: Refactor inital and default role, reason, dropdown ID setup
  const initialRole = roles[0] ? roles[0] : null;
  const initialRoleReason = reasons.find(
    reason => initialRole && initialRole.id === reason.withRoleId
  );

  const defaultRoleId = initialRole ? initialRole.id : null;

  const defaultReasonId =
    initialRoleReason && initialRoleReason.values[0]
      ? initialRoleReason.values[0].reasonOptionId
      : null;
  const defaultDropdownId =
    initialRoleReason &&
    initialRoleReason.values[0] &&
    initialRoleReason.values[0].children &&
    initialRoleReason.values[0].children[0]
      ? initialRoleReason.values[0].children[0].id
      : null;
  const defaultComments = "";
  const currentDate = new Date();
  const defaultDate = new Date(currentDate.getTime() + 14 * 24 * 60 * 60 * 1000);

  const [roleSelected, setRoleSelected] = useState(defaultRoleId);
  const [reasonIdSelected, setReasonIdSelected] = useState(defaultReasonId);
  const [reasonDropdownIdSelected, setReasonDropdownIdSelected] = useState(defaultDropdownId);
  const [reasonComments, setReasonComments] = useState(defaultComments);
  const [followUpDate, setFollowUpDate] = useState(defaultDate);

  const [queryStep, setQueryStep] = useState(INITIAL_STEP);

  // validate on comments
  const [displayRequiredCommentsWarning, setDisplayRequiredCommentsWarning] = useState(false);

  const onRoleClick = (roleId: string) => {
    setRoleSelected(roleId);
    const reason = reasons.find(reason => reason.withRoleId === roleId);

    const reasonId =
      reason && reason.values && reason.values[0] && reason.values[0].reasonOptionId
        ? reason.values[0].reasonOptionId
        : null;
    const reasonDropdownId =
      reason &&
      reason.values &&
      reason.values[0] &&
      reason.values[0].children &&
      reason.values[0].children[0] &&
      reason.values[0].children[0].id
        ? reason.values[0].children[0].id
        : null;

    setReasonIdSelected(reasonId);
    setReasonDropdownIdSelected(reasonDropdownId);
    setReasonComments(defaultComments);
    setFollowUpDate(defaultDate);
  };

  const onReasonClick = (reasonId: UUID) => {
    setReasonIdSelected(reasonId);

    const roleReason = reasons
      .find(roleReason => roleReason.withRoleId === roleSelected)
      ?.values.find(reason => reason.reasonOptionId === reasonId);
    const reasonDropdownId =
      roleReason && roleReason.children && roleReason.children[0] && roleReason.children[0]
        ? roleReason.children[0].id
        : null;

    setReasonDropdownIdSelected(reasonDropdownId);
  };

  const onReasonDropdownClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reasonDropdownId = e.target.value;
    setReasonDropdownIdSelected(reasonDropdownId);
  };

  const onCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReasonComments(e.target.value);
    areCommentsRequiredAndMissing();
  };

  const onCancelClick = () => {
    setQueryStep(INITIAL_STEP);

    setRoleSelected(defaultRoleId);
    setReasonIdSelected(defaultReasonId);
    setReasonDropdownIdSelected(defaultDropdownId);
    setReasonComments(defaultComments);
    setFollowUpDate(defaultDate);

    setCreateQuery(!createQuery);
  };

  const areCommentsRequiredAndMissing = (): boolean => {
    // validate comments
    const requiredComments = queryStep == 2 && reasonComments.length == 0;
    setDisplayRequiredCommentsWarning(requiredComments);
    return requiredComments;
  };

  const onSubmitClick = () => {
    if (!areCommentsRequiredAndMissing()) {
      if (queryStep === TOTAL_STEPS) {
        // Send query form data to API to save in database
        // Clear form data and collapse form
        dispatch(
          openQuery({
            queryObjectType: queryType,
            objectId: queryTypeId,
            studyId: studyId,
            caseId: caseId,
            category: QueryRecordCategory.Other, // TODO: Category not used anymore
            categoryOtherText: "",
            followUpInDays: getDaysFromDateSelected(followUpDate),
            withRoleId: roleSelected,
            responseOptionId: reasonIdSelected,
            childResponseOptionId: reasonDropdownIdSelected,
            commentText: reasonComments ? reasonComments : null
          })
        );

        onCancelClick();
      } else {
        updateStep(1);
      }
    }
  };

  const onBackClick = () => {
    updateStep(-1);
  };

  const updateStep = (value: number) => {
    const currentStep = queryStep + value;
    setQueryStep(currentStep);
  };

  // permissions to assign a query to a specific role i.e  create query 'with role'
  const roleToAssignQueryPermissions = new Map([
    [RoleName.SystemAdmin, [RolePermissions.QP_SystemAdminAssignedtostudytheImageCaseQuery]],
    [RoleName.StudyAdmin, [RolePermissions.QP_StudyAdminAssignedtostudytheImageCaseQuery]],
    [RoleName.ISC, [RolePermissions.QP_ISCAssignedtostudytheImageCaseQuery]],
    [RoleName.DM, [RolePermissions.QP_DMAssignedtostudytheImageCaseQuery]],
    [RoleName.PM, [RolePermissions.QP_PMAssignedtostudytheImageCaseQuery]],
    [RoleName.Sponsor, [RolePermissions.QP_SponsorAssignedtostudytheImageCaseQuery]],
    [RoleName.Site, [RolePermissions.QP_SiteAssignedtostudytheImageCaseQuery]],
    [RoleName.Uploader, [RolePermissions.QP_UploaderAssignedtostudytheImageCaseQuery]],
    [RoleName.CR, [RolePermissions.QP_CentralReaderAssignedtostudytheImageCaseQuery]],
    [RoleName.LabTech, [RolePermissions.QP_LabTechAssignedtostudytheImageCaseQuery]]
  ]);

  function canAssignQueryToRole(roleName: RoleName): Boolean {
    const permissions = roleToAssignQueryPermissions.get(roleName) || [];
    return loggedInUser.can(permissions);
  }

  const queryWithForm = () => {
    const filteredRoles = roles.filter(role => canAssignQueryToRole(role.name));

    const roleRadioButtons = filteredRoles.map(role => {
      return (
        <Label
          key={"l_" + role.id}
          style={{ display: "flex", cursor: "pointer", marginTop: "5px" }}
        >
          <Radio
            key={"r_" + role.id}
            checked={role.id === roleSelected}
            onClick={() => onRoleClick(role.id)}
          />
          <Text key={"t_" + role.id}>{roleLabel(role)}</Text>
        </Label>
      );
    });

    return (
      <>
        <Heading textAlign="left" as="h6">
          Query With
        </Heading>
        <div>{roleRadioButtons}</div>
      </>
    );
  };

  const queryReasonForm = () => {
    const role = roles.find(role => role.id === roleSelected);
    const roleName = role ? roleLabel(role) : "";
    const roleReasons =
      role && role.id
        ? reasons
            .find(roleReason => roleReason.withRoleId === role.id)
            ?.values.slice()
            .sort((a, b) => {
              if (a.reasonText === "Other") return 1; // Move "Other" to the end
              if (b.reasonText === "Other") return -1; // Move "Other" to the end
              return a.reasonText.localeCompare(b.reasonText); // Regular alphabetical order
            })
        : [];

    return (
      <>
        <Text style={{ fontWeight: "bold", fontSize: "14px" }}>Query with: {roleName}</Text>

        <Heading textAlign="left" style={{ fontSize: "18px" }}>
          Reason for Query
        </Heading>
        {roleReasons
          ?.filter(roleReason => roleReason.reasonOptionIsActive)
          .map((roleReason, rrIndex) => {
            return (
              <Box key={"rr_" + rrIndex.toString()}>
                <Label
                  key={"rl_" + rrIndex.toString()}
                  style={{ display: "flex", cursor: "pointer", marginTop: "5px" }}
                >
                  <Radio
                    key={roleReason.reasonOptionId}
                    checked={roleReason.reasonOptionId === reasonIdSelected}
                    onClick={() => onReasonClick(roleReason.reasonOptionId)}
                  />
                  {roleReason.reasonText}
                </Label>

                {roleReason.children &&
                roleReason.children.length > 0 &&
                roleReason.reasonOptionId === reasonIdSelected ? (
                  <div
                    key={"rrd_" + rrIndex.toString()}
                    style={{ marginTop: "2px", marginBottom: "15px" }}
                  >
                    {/* TODO: Move colour styling to classes in App.css */}
                    <span
                      key={"rrs_" + rrIndex.toString()}
                      style={{ color: "#767676", fontSize: "12px" }}
                    >
                      Select {roleReason.reasonText}
                    </span>

                    <Select
                      key={"rrsl_" + rrIndex.toString()}
                      onChange={onReasonDropdownClick}
                      defaultValue={reasonDropdownIdSelected}
                    >
                      {roleReason.children.map(child => {
                        return (
                          <option key={child.id} value={child.id}>
                            {child.reasonText}
                          </option>
                        );
                      })}
                    </Select>
                  </div>
                ) : null}
              </Box>
            );
          })}

        <Label style={{ display: "flex", cursor: "pointer", marginTop: "5px" }}>* Comments</Label>
        <TextArea
          placeholder="Add additional context here..."
          className="query-comment-textarea"
          onChange={onCommentChange}
          defaultValue={reasonComments}
        />
        {displayRequiredCommentsWarning && (
          <div style={{ display: "flex", cursor: "pointer", marginTop: "5px", color: "red" }}>
            &nbsp;&nbsp;Comments are mandatory.
          </div>
        )}
      </>
    );
  };

  const queryFollowUpDateForm = () => {
    return (
      <>
        <Heading textAlign="left" style={{ fontSize: "18px" }}>
          Select Follow Up Date
        </Heading>
        <Label style={{ color: "#767676" }}>Flag for follow-up</Label>
        <br />

        <FollowUpDatePicker selectedDate={followUpDate} setSelectedDate={setFollowUpDate} />
      </>
    );
  };

  const queryFormConfirmation = () => {
    const role = roles.find(role => role.id === roleSelected);
    const roleName = role ? roleLabel(role) : "";
    const roleReasons =
      role && role.id
        ? reasons.find(roleReason => roleReason.withRoleId === role.id)?.values.slice()
        : [];
    const roleReason = roleReasons
      ? roleReasons.find(reason => reason.reasonOptionId === reasonIdSelected)
      : null;
    const roleReasonDropdown =
      roleReason && roleReason.children
        ? roleReason.children.find(child => child.id === reasonDropdownIdSelected)
        : null;

    return (
      <>
        <Heading textAlign="left" style={{ fontSize: "18px" }}>
          Please confirm the query details
        </Heading>

        <Label style={{ color: "#767676" }}>Query With:</Label>
        <br />

        <Text>{roleName}</Text>
        <br />

        <Label style={{ color: "#767676" }}>Query Reason:</Label>
        <br />

        <Text>
          {roleReason?.reasonText}
          {roleReasonDropdown ? ` - ${roleReasonDropdown.reasonText}` : null}
        </Text>
        <br />

        <Label style={{ color: "#767676" }}>Additional Comments:</Label>
        <br />

        <Text>{reasonComments}</Text>
        <br />

        <Label style={{ color: "#767676" }}>
          Flag for follow up on:{" "}
          <Text style={{ color: "#111111" }}>
            <Timestamp date={followUpDate} />
          </Text>
        </Label>
      </>
    );
  };

  const generateStepDots = () => {
    const dots = Array.from({ length: TOTAL_STEPS }, (_, i) => {
      const step = i + 1;
      return step === queryStep ? (
        <FontAwesomeIcon
          key={`step-dot-${i}`}
          icon={solidCircle}
          style={{ color: "#4095bf", margin: "2px" }}
        />
      ) : (
        <FontAwesomeIcon
          key={`step-dot-${i}`}
          icon={emptyCircle}
          style={{ color: "#AAAAAA", margin: "2px" }}
        />
      );
    });

    return dots;
  };

  return (
    <Box mt={10}>
      <Box display="flex" width="100%" mb={2}>
        <Box textAlign="left">{generateStepDots()}</Box>
        <Box ml={"auto"}>
          <button style={{ border: "none", backgroundColor: "inherit", cursor: "pointer" }}>
            <FontAwesomeIcon icon={faX} size="1x" onClick={onCancelClick} />
          </button>
        </Box>
      </Box>
      <div className="form">
        {queryStep == 1 ? queryWithForm() : null}
        {queryStep == 2 ? queryReasonForm() : null}
        {queryStep == 3 ? queryFollowUpDateForm() : null}
        {queryStep == 4 ? queryFormConfirmation() : null}
      </div>
      <br />
      <Box display="flex" width="100%">
        <Box ml={"auto"}>
          <Button
            onClick={onSubmitClick}
            style={{ background: "#007EB5", color: "#FFFFFF" }}
            mr={1}
          >
            {queryStep < 4 ? "Next" : "Submit"}
          </Button>
          {queryStep == 1 ? (
            <Button onClick={onCancelClick}>Cancel</Button>
          ) : (
            <Button onClick={onBackClick}>Back</Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CreateQueryFlow;
