import React from "react";
import { Box, Button, Callout, Dialog, Intent, Label, Text, TextInput } from "@blasterjs/core";
import { DialogBody, DialogFooter, DialogHeader } from "../components/DialogLayout";
import ReasonForChange from "../components/ReasonForChange";
import { useAppDispatch, useAppSelector } from "../hooks";
import { ImageMetadataForm, closeImageDialog, editImage, updateImage } from "../slices/imageDialog";
import { RolePermissions } from "../permissions";

interface EditableMetadataFields {
  readonly key: Extract<keyof ImageMetadataForm, "name" | "accessionNumber" | "biopsyLocation">;
  readonly label: string;
  readonly perm: RolePermissions;
}

const editableFields: readonly EditableMetadataFields[] = [
  { key: "name", label: "File Name", perm: RolePermissions.AP_ImageData_EditFileName },
  {
    key: "accessionNumber",
    label: "Accession #",
    perm: RolePermissions.AP_ImageData_EditAccessionNumber
  },
  {
    key: "biopsyLocation",
    label: "Anatomical Segment",
    perm: RolePermissions.AP_ImageData_EditAnatomicalSegment
  }
];

const ImageDialog = () => {
  const dispatch = useAppDispatch();
  const imageDialog = useAppSelector(state => state.imageDialog);
  const loggedInUser = useAppSelector(state => state.auth.loggedInUser);

  const updateField = <
    Key extends keyof ImageMetadataForm,
    PartialUpdate extends Partial<ImageMetadataForm[Key]>
  >(
    key: Key,
    value: PartialUpdate
  ) => {
    imageDialog.isOpen &&
      dispatch(
        editImage({
          ...imageDialog.image.data,
          [key]: {
            ...imageDialog.image.data[key],
            ...value
          }
        })
      );
  };
  const updateFieldWithValue = (key: keyof ImageMetadataForm) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    return updateField(key, { value: e.currentTarget.value });
  };
  const updateFieldWithReasonForChange = (key: keyof ImageMetadataForm) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => updateField(key, { reasonForChange: e.currentTarget.value });
  const closeDialog = () => {
    dispatch(closeImageDialog());
  };
  const onSave = () => {
    dispatch(updateImage());
  };
  const errorText =
    "image" in imageDialog && "errorMessage" in imageDialog.image ? (
      <Box mb={2}>
        <Callout intent={Intent.DANGER}>
          <Text>{imageDialog.image.errorMessage}</Text>
        </Callout>
      </Box>
    ) : null;

  function fieldSaveable(fieldName: "name" | "accessionNumber" | "biopsyLocation"): boolean {
    const newValue = (imageDialog.isOpen && imageDialog.image.data[fieldName].value) || "";
    const oldValue = (imageDialog.isOpen && imageDialog.savedImage[fieldName]) || "";
    const rfc =
      (imageDialog.isOpen &&
        "reasonForChange" in imageDialog.image.data[fieldName] &&
        (imageDialog.image.data[fieldName] as any).reasonForChange) ||
      "";
    return oldValue == newValue || rfc.length > 0 || oldValue.length == 0;
  }

  const disableSave: boolean =
    !fieldSaveable("name") || !fieldSaveable("accessionNumber") || !fieldSaveable("biopsyLocation");

  return imageDialog.isOpen ? (
    <Dialog
      isOpen={
        // Avoid flicker by waiting to open dialog until image has loaded
        imageDialog.isOpen && !("isPending" in imageDialog.image)
      }
      onRequestClose={closeDialog}
      appElementId="root"
    >
      <DialogHeader title="Edit Image" closeDialog={closeDialog} />
      <DialogBody>
        {errorText}
        {"resource" in loggedInUser &&
          !loggedInUser.resource.can([RolePermissions.AP_ImageViewer_ViewButtonPassLabQC]) && (
            <Box>
              <Label>
                File Name&nbsp;
                <TextInput
                  placeholder="File Name"
                  mb={2}
                  value={imageDialog.savedImage.name}
                  disabled={true}
                />
              </Label>
            </Box>
          )}
        {editableFields.map(
          ({ key, label, perm }) =>
            "resource" in loggedInUser &&
            loggedInUser.resource.can([perm]) && (
              <div key={key}>
                <hr />
                <Box>
                  <Label>
                    {label}&nbsp;
                    <TextInput
                      placeholder={label}
                      mb={2}
                      value={imageDialog.image.data[key].value || ""}
                      onChange={updateFieldWithValue(key)}
                    />
                  </Label>
                </Box>
                {imageDialog.isOpen &&
                  imageDialog.savedImage[key] !== null &&
                  imageDialog.savedImage[key] !== imageDialog.image.data[key].value && (
                    <Box>
                      <ReasonForChange
                        rkey="imageData_reason"
                        mb={2}
                        value={
                          "reasonForChange" in imageDialog.image.data[key]
                            ? imageDialog.image.data[key].reasonForChange
                            : ""
                        }
                        onChange={updateFieldWithReasonForChange(key)}
                      />
                    </Box>
                  )}
              </div>
            )
        )}
      </DialogBody>
      <DialogFooter>
        <Box>
          <Button
            appearance="prominent"
            intent="primary"
            onClick={onSave}
            disabled={disableSave}
            isLoading={"isSaving" in imageDialog.image}
          >
            Save
          </Button>
        </Box>
        <Box>
          <Button onClick={closeDialog}>Cancel</Button>
        </Box>
      </DialogFooter>
    </Dialog>
  ) : null;
};

export default ImageDialog;
